import { useQuery } from '@apollo/client'
import { Link, Message, TextVerticalSpacer } from '@superhi/design'

import { API } from '../../api'
import Page from '../../components/Page'
import PageLayout from '../../components/PageLayout'
import { PageConfig } from '../../routing'
import Membership from '../Membership/pages/View'
import InvoiceItem from '../Membership/pages/View/components/InvoiceHistory/components/InvoiceItem'
import { Invoices } from '../Membership/pages/View/components/InvoiceHistory/styles'

import { ORDERS } from './queries'
import ROUTE, { PathArgs } from './route'

const OrderHistory: PageConfig<unknown, PathArgs> = () => {
  const { data, loading } = useQuery<API.OrderHistory>(ORDERS)

  const orders = data?.session?.orders

  return (
    <Page title="Order history">
      <PageLayout title="Order history" loading={loading}>
        <TextVerticalSpacer>
          {(orders?.nodes.length || 0) > 0 ? (
            <Invoices>
              {orders?.nodes.map((order) => (
                <InvoiceItem
                  key={order.id}
                  title={
                    order.lineItems?.nodes
                      .map((lineItem) => `${lineItem.quantity} x ${lineItem.variant.title}`)
                      .join(', ') || 'Missing items'
                  }
                  dateTime={order.insertedAt}
                  total={order.total}
                  receiptUrl={order.receiptUrl}
                  version={order.version}
                />
              ))}
            </Invoices>
          ) : (
            <Message color="GREY_10">You haven&apos;t made any purchases.</Message>
          )}

          <Message level="3">
            Looking for your Membership receipts? Find them{' '}
            <Link href={Membership.route.path()}>here</Link>.
          </Message>
        </TextVerticalSpacer>
      </PageLayout>
    </Page>
  )
}

OrderHistory.route = ROUTE

export default OrderHistory
