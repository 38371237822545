import { useMutation, useQuery } from '@apollo/client'
import { Button, Buttons, Form, FormOptionalGroupInput, FormTextInput } from '@superhi/design'
import { FormSelectInputCountryCode } from '@superhi/utils'

import { API } from '../../../../api'
import Page from '../../../../components/Page'
import PageLayout, { SPACING } from '../../../../components/PageLayout'
import { Redirect, PageConfig } from '../../../../routing'
import Membership from '../View'
import { handleErrors } from '../../../../utils'

import { FIELD_CONFIGS } from './config'
import { FormValues } from './types'
import { UPDATE } from './mutations'
import { SESSION } from './queries'
import FormTaxIdTypeInput from './components/FormTaxIdTypeInput'
import ROUTE, { PathArgs } from './route'

const UpdateBillingDetails: PageConfig<unknown, PathArgs> = () => {
  const { data, loading } = useQuery<API.MembershipSettings_UpdateBillingDetails>(SESSION)
  const [updateMembership, updateMembershipResult] = useMutation<
    API.Membership_UpdateBillingDetails,
    API.Membership_UpdateBillingDetailsVariables
  >(UPDATE)

  if (loading) {
    return (
      <Page title="Update your billing details">
        <PageLayout
          backUrl={Membership.route.path()}
          title="Update your billing details"
          subtitle="Loading..."
        />
      </Page>
    )
  }

  const plan = data?.session?.membership?.plan

  if (!plan) {
    return <Redirect to={Membership.route.path()} />
  }

  const hasUpdated =
    updateMembershipResult.called &&
    !updateMembershipResult.loading &&
    !updateMembershipResult.error

  return (
    <Page title="Update your billing details">
      <PageLayout
        backUrl={Membership.route.path()}
        title={
          !hasUpdated ? 'Update your billing details' : 'Your billing details have been updated!'
        }
        footer={
          hasUpdated && (
            <Buttons align="center">
              <Button href={Membership.route.path()}>Done</Button>
            </Buttons>
          )
        }
      >
        {!hasUpdated && (
          <Form<FormValues>
            name="membership-billing-details-update"
            initialValues={{
              hasBillingDetails: !!(plan.billingContact || plan.billingAddress || plan.taxId),
              hasBillingDetailsContact: !!plan.billingContact,
              billingDetailsContact: {
                name: plan.billingContact?.name || '',
                email: plan.billingContact?.email || '',
              },
              hasBillingDetailsAddress: !!plan.billingAddress,
              billingDetailsAddress: {
                line1: plan.billingAddress?.line1 || '',
                line2: plan.billingAddress?.line2 || '',
                city: plan.billingAddress?.city || '',
                state: plan.billingAddress?.state || '',
                postalCode: plan.billingAddress?.postalCode || '',
                country: plan.billingAddress?.country || '',
              },
              hasBillingDetailsTaxId: !!plan.taxId,
              billingDetailsTaxId: {
                type: plan.taxId?.type || API.TaxIdType.AE_TRN,
                value: plan.taxId?.value || '',
              },
            }}
            submitButtonText="Update billing details"
            submitButtonAlign="center"
            spacing={SPACING}
            onSubmit={async (values, helpers) => {
              try {
                await updateMembership({
                  variables: {
                    billingContact: values.hasBillingDetailsContact
                      ? values.billingDetailsContact
                      : null,
                    billingAddress: values.hasBillingDetailsAddress
                      ? values.billingDetailsAddress
                      : null,
                    taxId: values.hasBillingDetailsTaxId ? values.billingDetailsTaxId : null,
                  },
                })
              } catch (e) {
                handleErrors(e, helpers.setFieldError, {
                  taxId: 'billingDetailsTaxId.value',
                })
              }
            }}
          >
            <Form.Rows fit="tight">
              <FormOptionalGroupInput
                name={FIELD_CONFIGS.HAS_BILLING_DETAILS_CONTACT.name}
                label="Contact"
                ariaLabel="Contact"
              >
                <Form.Rows fit="tight">
                  <FormTextInput {...FIELD_CONFIGS.BILLING_DETAILS_CONTACT.NAME} />
                  <FormTextInput {...FIELD_CONFIGS.BILLING_DETAILS_CONTACT.EMAIL} />
                </Form.Rows>
              </FormOptionalGroupInput>

              <FormOptionalGroupInput
                name={FIELD_CONFIGS.HAS_BILLING_DETAILS_ADDRESS.name}
                label="Address"
                ariaLabel="Address"
              >
                <Form.Rows fit="tight">
                  <FormTextInput {...FIELD_CONFIGS.BILLING_DETAILS_ADDRESS.LINE_1} />
                  <FormTextInput {...FIELD_CONFIGS.BILLING_DETAILS_ADDRESS.LINE_2} />
                  <FormTextInput {...FIELD_CONFIGS.BILLING_DETAILS_ADDRESS.CITY} />
                  <FormTextInput {...FIELD_CONFIGS.BILLING_DETAILS_ADDRESS.STATE} />
                  <Form.Rows
                    fit="tight"
                    split={{ small: [100, 100], medium: [50, 50], large: [50, 50] }}
                  >
                    <FormTextInput {...FIELD_CONFIGS.BILLING_DETAILS_ADDRESS.POSTAL_CODE} />
                    <FormSelectInputCountryCode
                      {...FIELD_CONFIGS.BILLING_DETAILS_ADDRESS.COUNTRY}
                    />
                  </Form.Rows>
                </Form.Rows>
              </FormOptionalGroupInput>

              <FormOptionalGroupInput
                name={FIELD_CONFIGS.HAS_BILLING_DETAILS_TAX_ID.name}
                label="Tax ID"
                ariaLabel="Tax ID"
              >
                <Form.Rows
                  fit="tight"
                  split={{ small: [100, 100], medium: [50, 50], large: [50, 50] }}
                >
                  <div>
                    <FormTaxIdTypeInput {...FIELD_CONFIGS.BILLING_DETAILS_TAX_ID.TYPE} />
                  </div>
                  <FormTextInput {...FIELD_CONFIGS.BILLING_DETAILS_TAX_ID.VALUE} />
                </Form.Rows>
              </FormOptionalGroupInput>
            </Form.Rows>
          </Form>
        )}
      </PageLayout>
    </Page>
  )
}

UpdateBillingDetails.route = ROUTE

export default UpdateBillingDetails
