import React from 'react'
import { theme } from '@superhi/design'
import Head from 'next/head'

const DEFAULT_TITLE = 'Account'
const DEFAULT_DESCRIPTION = 'Manage your SuperHi account'

type Props = {
  title?: string
  description?: string
  noIndex?: boolean
}

const SEO: React.FC<Props> = ({
  title = DEFAULT_TITLE,
  description = DEFAULT_DESCRIPTION,
  noIndex = false,
}) => {
  const fixedTitle = `${title} – SuperHi`

  return (
    <Head>
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <title>{fixedTitle}</title>
      {description && <meta name="description" content={description} />}
      <meta name="theme-color" content={theme.colors.BLUE_50} />

      <meta name="twitter:title" content={fixedTitle} />
      <meta name="twitter:description" content={description} />

      <meta property="og:title" content={fixedTitle} />
      <meta property="og:description" content={description} />

      {noIndex && <meta name="robots" content="noindex" />}
    </Head>
  )
}

export default SEO
