import React from 'react'
import { useQuery } from '@apollo/client'
import { getImgProps, UserDropdownMenu } from '@superhi/design'

import { API } from '../../../../api'
import Membership from '../../../../pages/Membership/pages/View'
import OrderHistory from '../../../../pages/OrderHistory'
import Settings from '../../../../pages/Settings/pages/View'
import { DASHBOARD_URL, EDITOR_URL } from '../../../../config'

import { SESSION } from './queries'

export const useSiteHeaderUserProps = (): Pick<
  React.ComponentProps<typeof UserDropdownMenu>,
  'user' | 'items'
> | null => {
  const { data: sessionData } = useQuery<API.SiteHeaderComponentSession>(SESSION, {
    fetchPolicy: 'cache-and-network',
  })

  const session = sessionData?.session

  if (!session) return null

  const items: React.ComponentProps<typeof UserDropdownMenu>['items'] = [
    {
      items: [
        {
          display: 'Student dashboard',
          href: DASHBOARD_URL,
          isExternal: true,
        },
        {
          display: 'SuperHi Editor',
          href: EDITOR_URL,
          isExternal: true,
        },
      ],
    },
    {
      items: [
        {
          display: 'Profile',
          href: `${DASHBOARD_URL}/users/${session.username}`,
          isExternal: true,
        },
        {
          display: 'Membership',
          href: Membership.route.path(),
        },
        {
          display: 'Order history',
          href: OrderHistory.route.path(),
        },
        // {
        //   display: 'Course certificates',
        //   href: OrderHistory.route.path(),
        // },
        {
          display: 'Settings',
          href: Settings.route.path(),
        },
        // {
        //   display: 'Help Center',
        //   href: `${MARKETING_SITE_URL}/help-center`,
        //   isExternal: true,
        // },
      ],
    },
    {
      items: [{ display: 'Log out', href: '/logout' }],
    },
  ]

  return {
    user: {
      name: session.username,
      email: session.email,
      image: session.avatar?.url
        ? getImgProps(session.avatar.url, {
            small: 5,
            medium: 5,
            large: 5,
            xlarge: 5,
          })
        : { src: '' },
    },
    items,
  }
}
