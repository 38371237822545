import { theme } from '@superhi/design'
import styled from 'styled-components'

export const Wrapper = styled.div`
  &:not(:last-child):after {
    content: '';
    display: block;
    height: 1px;
    background-color: ${theme.colors.GREY_60};
    margin: 32px 0;
  }
`
