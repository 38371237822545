import { RouteConfig } from '../../routing/types'

export type PathArgs = any[]

const ROUTE: RouteConfig<PathArgs> = {
  name: 'home',
  path: () => '/',
  type: 'private',
}

export default ROUTE
