import { FormTextInput } from '@superhi/design'
import { FieldConfig } from '@superhi/utils'

export const PASSWORD: FieldConfig<typeof FormTextInput> = {
  name: 'password',
  type: 'password',
  label: 'Password',
  ariaLabel: 'Password',
  placeholder: '············',
  required: true,
}

export const PASSWORD_CONFIRMATION: FieldConfig<typeof FormTextInput> = {
  name: 'passwordConfirmation',
  type: 'password',
  label: 'Confirm password',
  ariaLabel: 'Confirm password',
  placeholder: '············',
  required: true,
}
