import { gql } from '@apollo/client'

export const FRAGMENT = gql`
  fragment MembershipUpdatePlan on UserMembershipPlan {
    product {
      title
    }
    currentPayment {
      value
      currencyCode
    }
    currentPrice {
      id
      value
      currencyCode
      recurring {
        interval
      }
    }
    renewalDate
    nextPayment {
      value
      currencyCode
    }
    nextPrice {
      id
      value
      currencyCode
      recurring {
        interval
      }
    }
  }
`

export const PLAN = gql`
  query Membership_UpdatePlan {
    session {
      membership {
        plan {
          ...MembershipUpdatePlan
        }
      }
    }
  }

  ${FRAGMENT}
`

export const PLAN_PREVIEW = gql`
  query Membership_UpdatePlanPreview($interval: StripePriceRecurringInterval!) {
    userMembershipPlanUpdatePreview(interval: $interval) {
      currentPayment {
        value
        currencyCode
      }
      currentPrice {
        value
        currencyCode
        recurring {
          interval
        }
      }
      nextPayment {
        value
        currencyCode
      }
      nextPrice {
        id
        value
        currencyCode
        recurring {
          interval
        }
      }
      renewalDate
    }
  }
`
