import { theme, VerticalSpacer } from '@superhi/design'
import styled, { createGlobalStyle } from 'styled-components'

export const Global = createGlobalStyle`
    body {
        background-color: ${theme.colors.PALE_BLUE_40};
    }
`

export const Wrapper = styled(VerticalSpacer).attrs({
  amount: {
    small: 16,
    medium: 16,
    large: 24,
  },
})`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 560px;
  margin: 0 auto;
  padding: 40px 0;

  @media ${theme.breakpoints.medium} {
    padding: 56px 0;
  }
`

export const CardWrapper = styled.div`
  width: 100%;
  max-width: 640px;
`

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 16px 0;

  @media ${theme.breakpoints.large} {
    padding: 16px;
  }
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  margin: 0 16px;
`

export const UserDropdownMenuWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 16px;
`
