import { gql } from '@apollo/client'

export const SESSION = gql`
  query MembershipSettings_InvoiceHistory_Session {
    session {
      id
      membership {
        id
        status
        invoices {
          id
          product {
            id
            title
          }
          price {
            id
            recurring {
              interval
              intervalCount
            }
          }
          invoiceUrl
          receiptUrl
          total {
            value
            currencyCode
          }
          createdAt
        }
      }
    }
  }
`
