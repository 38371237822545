import { GoogleAnalytics, Heap, HelpScout } from '../../../services'

export const loggedOut = () => {
  try {
    // helpscout
    HelpScout.logout()

    // heap
    Heap.resetIdentity()
    Heap.clearEventProperties()

    // google analytics
    GoogleAnalytics.identify()
  } catch (e) {}
}
