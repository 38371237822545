import React from 'react'
import { Body, Buttons, Heading, TextVerticalSpacer } from '@superhi/design'
import { CommonProps } from '@superhi/design/dist/cjs/components/types'

import { Wrapper } from './styles'

type Props = CommonProps<{
  title?: string
  buttons?: React.ReactElement
  loading?: boolean
}>

const Section: React.FC<Props> = ({ title, buttons, loading, children, ...commonProps }) => (
  <Wrapper {...commonProps}>
    <TextVerticalSpacer level="3">
      {title && (
        <Heading color="BLUE_50" level="4">
          {title}
        </Heading>
      )}
      {loading ? (
        <Body level="3">Loading...</Body>
      ) : (
        <TextVerticalSpacer level="1">
          <TextVerticalSpacer level="2">{children}</TextVerticalSpacer>
          {buttons && <Buttons>{buttons}</Buttons>}
        </TextVerticalSpacer>
      )}
    </TextVerticalSpacer>
  </Wrapper>
)

export default Section
