const name: 'redirectExternal' = 'redirectExternal'

const get = () => sessionStorage.getItem(name)

const set = (url: string) =>
  sessionStorage.setItem(name, /^((http(s*)):\/\/)/.test(url) ? url : `http://${url}`)

const remove = () => sessionStorage.removeItem(name)

const redirectExternalUtils = { name, get, set, remove }

export default redirectExternalUtils
