import { gql } from '@apollo/client'

import { FRAGMENT } from './queries'

export const CANCEL_MEMBERSHIP = gql`
  mutation Membership_CancelPlan_mutation {
    membershipCancel {
      ...MembershipCancelPlan
    }
  }

  ${FRAGMENT}
`
