import { gql } from '@apollo/client'

export const PLAN = gql`
  query Membership_PlanDetails {
    session {
      id
      membership {
        id
        plan {
          product {
            title
          }
          currentPayment {
            value
            currencyCode
          }
          currentPrice {
            id
            value
            currencyCode
            recurring {
              interval
              intervalCount
            }
          }
          nextPayment {
            value
            currencyCode
          }
          nextPrice {
            value
            currencyCode
            recurring {
              interval
            }
          }
          renewalDate
        }
      }
    }
  }
`
