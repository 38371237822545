import { gql } from '@apollo/client'

import { FRAGMENT } from '../View/components/BillingDetails/queries'

export const UPDATE = gql`
  mutation Membership_UpdateBillingDetails(
    $billingContact: MembershipBillingContactInput
    $billingAddress: MembershipBillingAddressInput
    $taxId: TaxIdInput
  ) {
    membershipDetailsUpdate(
      billingContact: $billingContact
      billingAddress: $billingAddress
      taxId: $taxId
    ) {
      id
      membership {
        ...MembershipSettings_BillingDetails_Membership
      }
    }
  }

  ${FRAGMENT}
`
