import { gql } from '@apollo/client'

export const FRAGMENT = gql`
  fragment Settings_UserSettings on Session {
    id
    name
    username
    email
  }
`

export const SETTINGS = gql`
  query Settings_Session {
    session {
      ...Settings_UserSettings
      avatar {
        url
      }
    }
  }

  ${FRAGMENT}
`
