import Cookies from 'js-cookie'

import { IS_CYPRESS } from '../../config'

const name: 'auth-token' = 'auth-token'

const DOMAIN = '.superhi.com'

const get = (): string | undefined => Cookies.get(name)

const set = (token: string) => {
  if (process.env.NODE_ENV === 'development' || IS_CYPRESS) {
    Cookies.set(name, token, {
      expires: 7,
      path: '/',
    })
  }

  Cookies.set(name, token, { domain: DOMAIN, expires: 7, path: '/' })
}

const remove = () => {
  if (process.env.NODE_ENV === 'development' || IS_CYPRESS) {
    Cookies.remove(name, {
      path: '/',
    })
  }

  Cookies.remove(name, {
    domain: DOMAIN,
    path: '/',
  })
}

export default {
  name,
  get,
  set,
  remove,
}
