import { RouteConfig } from '../../../../routing/types'

export type PathArgs = any[]

const ROUTE: RouteConfig<PathArgs> = {
  name: 'settings',
  path: () => '/settings',
  type: 'private',
}

export default ROUTE
