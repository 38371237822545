import React from 'react'
import { useQuery } from '@apollo/client'
import { useRouter } from 'next/router'

import { API } from '../../../api'
import Loading from '../../../pages/Loading'
import Redirect from '../../../pages/Redirect'
import { SESSION } from '../queries'
import RedirectExternal, {
  utils as redirectExternalUtils,
} from '../../../components/RedirectExternal'

const RoutePublic: React.FC = ({ children }) => {
  const { data, loading } = useQuery<API.RouteSession>(
    SESSION,
    //   , {
    //   fetchPolicy: 'cache-and-network',
    //   notifyOnNetworkStatusChange: true,
    // }
  )

  if (loading && !data) {
    return <Loading />
  }

  const isLoggedIn = !!data?.session

  if (isLoggedIn) {
    if (redirectExternalUtils.get()) {
      return <RedirectExternal authToken={data?.session?.token || undefined} />
    }

    return (
      <>
        <Loading />
        <Redirect to={window.redirectInternal || '/'} />
      </>
    )
  }

  return <>{children}</>
}

export default RoutePublic
