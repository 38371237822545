import React from 'react'
import NextScript from 'next/script'

import { HEAP_APP_ID } from '../../config'

const identify = (config: { id: string }) => {
  try {
    window.heap?.identify(config.id)
  } catch (e) {
    console.log(e)
  }
}

const resetIdentity = () => {
  try {
    window.heap?.resetIdentity()
  } catch (e) {
    console.log(e)
  }
}

const addUserProperties = (data: object) => {
  try {
    window.heap?.addUserProperties(data)
  } catch (e) {
    console.log(e)
  }
}

const clearEventProperties = () => {
  try {
    window.heap?.clearEventProperties()
  } catch (e) {
    console.log(e)
  }
}

const addEventProperties = (data: object) => {
  try {
    clearEventProperties()
    window.heap?.addEventProperties(data)
  } catch (e) {
    console.log(e)
  }
}

const track = (eventName: string, data: object) => {
  try {
    window.heap?.track(eventName, data)
  } catch (e) {
    console.log(e)
  }
}

const Script = () => (
  <NextScript
    id="heap-1"
    type="text/javascript"
    dangerouslySetInnerHTML={{
      __html: `window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};heap.load("${HEAP_APP_ID}");`,
    }}
  />
)

const Heap = {
  Script,
  identify,
  resetIdentity,
  addUserProperties,
  clearEventProperties,
  addEventProperties,
  track,
}

export default Heap
