import { useRouter } from 'next/router'
import React from 'react'

type Props = {
  from?: string
  to: string
  statusCode?: number
}

const Redirect: React.FC<Props> = ({ from, to, statusCode }) => {
  const router = useRouter()

  React.useEffect(() => {
    // const url = new URL(`${SITE_URL}${to}`)
    if (from) {
      window.redirectInternal = from
    }

    router.replace(to)
  }, [])

  return null
}

export default Redirect
