import ResetPasswordRequest from '../pages/ResetPassword/pages/Request/route'
import ResetPasswordReset from '../pages/ResetPassword/pages/Reset/route'
import Home from '../pages/Home/route'
import LogIn from '../pages/LogIn/route'
import LogOut from '../pages/LogOut/route'
import CancelPlan from '../pages/Membership/pages/CancelPlan/route'
import UpdateBillingDetails from '../pages/Membership/pages/UpdateBillingDetails/route'
import UpdatePaymentDetailsStripe from '../pages/Membership/pages/UpdatePaymentDetails/route'
import Membership from '../pages/Membership/pages/View/route'
import OrderHistory from '../pages/OrderHistory/route'
import UpdateDetails from '../pages/Settings/pages/UpdateDetails/route'
import UpdatePassword from '../pages/Settings/pages/UpdatePassword/route'
import Settings from '../pages/Settings/pages/View/route'
import SignUp from '../pages/SignUp/route'
import redirectExternalUtils from '../components/RedirectExternal/utils'
import includeAuthTokenUtils from '../api/auth/includeAuthToken'

const ROUTES = {
  home: Home.path,
  login: LogIn.path,
  logout: LogOut.path,
  membership: Membership.path,
  'membership-cancel': CancelPlan.path,
  'membership-billing': UpdateBillingDetails.path,
  'membership-payment': UpdatePaymentDetailsStripe.path,
  'reset-password-request': ResetPasswordRequest.path,
  'reset-password-reset': ResetPasswordReset.path,
  'order-history': OrderHistory.path,
  settings: Settings.path,
  'settings-details': UpdateDetails.path,
  'settings-password': UpdatePassword.path,
  signup: SignUp.path,
}

export const buildPath = (routeName: keyof typeof ROUTES, params?: any) => ROUTES[routeName](params)

export const buildUrl = (
  baseUrl: string,
  routeName: Parameters<typeof buildPath>[0],
  options?: {
    params?: Parameters<typeof buildPath>[1]
    redirectUrl?: string
    includeAuthToken?: boolean
  },
) => {
  const url = new URL(`${baseUrl}${buildPath(routeName, options?.params)}`)

  if (options?.redirectUrl) {
    url.searchParams.set(redirectExternalUtils.name, options.redirectUrl)
  }

  if (options?.includeAuthToken) {
    url.searchParams.set(includeAuthTokenUtils.name, String(options.includeAuthToken))
  }

  return url.toString()
}
