import { RouteConfig } from '../../../../routing/types'

export type PathArgs = unknown[]

const ROUTE: RouteConfig<PathArgs> = {
  name: 'reset-password-request',
  path: () => '/reset-password',
  type: 'public',
}

export default ROUTE
