import React from 'react'
import { NextComponentType } from 'next'

import RoutePrivate from './Private'
import RoutePublic from './Public'
import { RouteType } from './types'

type Props = {
  type?: RouteType
  children: React.ReactNode
}

const Route: React.FC<Props> = ({ type = 'private', children }) => {
  switch (type) {
    case 'all': {
      // never redirect
      return <>{children}</>
    }
    case 'private': {
      // if not logged in, redirect
      return <RoutePrivate>{children}</RoutePrivate>
    }
    case 'public': {
      // if logged in, redirect
      return <RoutePublic>{children}</RoutePublic>
    }
  }
}

export default Route
