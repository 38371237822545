import { gql } from '@apollo/client'

export const SESSION = gql`
  query UseEvents_LoggedIn_Session {
    session {
      id
      name
      email
      type
      team {
        id
      }
      membership {
        id
        status
      }
    }
  }
`
