import React from 'react'
import { useQuery } from '@apollo/client'
import { useRouter } from 'next/router'

import { API } from '../../../api'
import Loading from '../../../pages/Loading'
import Redirect from '../../../pages/Redirect'
import { SESSION } from '../queries'

const RoutePrivate: React.FC = ({ children }) => {
  const router = useRouter()
  const { data, loading } = useQuery<API.RouteSession>(SESSION)

  if (loading && !data) {
    return <Loading />
  }

  const isLoggedIn = !!data?.session

  if (!isLoggedIn) {
    return (
      <>
        <Loading />
        <Redirect to="/login" from={router.asPath} />
      </>
    )
  }

  return <>{children}</>
}

export default RoutePrivate
