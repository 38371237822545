import React from 'react'

import includeAuthTokenUtils from '../../api/auth/includeAuthToken'
import { DASHBOARD_URL } from '../../config'
import { authTokenCookie } from '../../library'
import Page from '../Page'
import PageLayout from '../PageLayout'

import utils from './utils'

const getHref = (to?: string, authToken?: string) => {
  const url = new URL(utils.get() || to || DASHBOARD_URL)
  const includeAuthToken = includeAuthTokenUtils.get()

  if (includeAuthToken) {
    const fixedAuthToken = authTokenCookie.get() || authToken

    if (fixedAuthToken) {
      url.searchParams.set('authToken', fixedAuthToken)
    }
  }

  const href = url.toString()

  return href
}

export const redirectExternal = (to?: string, wait: number = 1000, authToken?: string) => {
  if (wait) {
    window.setTimeout(() => {
      window.location.href = getHref(to, authToken)
    }, wait)
  } else {
    window.location.href = getHref(to, authToken)
  }
}

type Props = {
  authToken?: string
  to?: string
  wait?: number
}

const RedirectExternal: React.FC<Props> = ({ to, wait, authToken }) => {
  React.useEffect(() => {
    redirectExternal(to, wait, authToken)
  }, [wait, to, authToken])

  return (
    <Page>
      <PageLayout title="Redirecting..." />
    </Page>
  )
}

export { default as utils } from './utils'
export default RedirectExternal
