import { RouteConfig } from '../../routing/types'

export type PathArgs = unknown[]

const ROUTE: RouteConfig<PathArgs> = {
  name: 'logout',
  path: () => '/logout',
  type: 'private',
}

export default ROUTE
