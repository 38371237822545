import { useMutation } from '@apollo/client'
import { Button, Buttons, Form } from '@superhi/design'
import React from 'react'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { FormStripeInput } from '@superhi/utils'

import { PageConfig } from '../../../../routing'
import { API } from '../../../../api'
import Page from '../../../../components/Page'
import PageLayout, { SPACING } from '../../../../components/PageLayout'
import Membership from '../View'
import { handleErrors } from '../../../../utils'

import * as CONFIG from './config'
import { UPDATE } from './mutations'
import StripeWrapper from './components/StripeWrapper'
import ROUTE, { PathArgs } from './route'

type FormValues = {
  paymentMethodId?: undefined
}

const UpdatePaymentDetails: React.FC = () => {
  const [updatePaymentDetails, updatePaymentDetailsResult] =
    useMutation<API.Membership_UpdatePaymentDetails>(UPDATE)

  const stripe = useStripe()
  const elements = useElements()

  if (!stripe || !elements) {
    return (
      <Page title="Update your payment details">
        <PageLayout
          backUrl={Membership.route.path()}
          title="Update your payment details"
          subtitle="Loading..."
        />
      </Page>
    )
  }

  const hasUpdated =
    updatePaymentDetailsResult.called &&
    !updatePaymentDetailsResult.loading &&
    !updatePaymentDetailsResult.error

  return (
    <Page title="Update your payment details">
      <PageLayout
        backUrl={Membership.route.path()}
        title={
          !hasUpdated ? 'Update your payment details' : 'Your payment details have been updated!'
        }
        footer={
          hasUpdated && (
            <Buttons align="center">
              <Button href={Membership.route.path()}>Done</Button>
            </Buttons>
          )
        }
      >
        {!hasUpdated && (
          <Form<FormValues>
            name="membership-payment-update"
            initialValues={{ paymentMethodId: undefined }}
            submitButtonText="Update payment details"
            submitButtonAlign="center"
            spacing={SPACING}
            onSubmit={async (_, helpers) => {
              const card = elements.getElement(CardElement)

              if (!card) {
                helpers.setFieldError(CONFIG.STRIPE_CARD.name, 'An unknown error occurred.')
              } else {
                const paymentMethodResult = await stripe.createPaymentMethod({
                  type: 'card',
                  card: card,
                })

                if (paymentMethodResult.error) {
                  helpers.setFieldError(
                    CONFIG.STRIPE_CARD.name,
                    paymentMethodResult.error.message || 'An unknown error occurred.',
                  )
                } else {
                  if (paymentMethodResult.paymentMethod) {
                    try {
                      await updatePaymentDetails({
                        variables: {
                          stripePaymentMethodId: paymentMethodResult.paymentMethod.id,
                        },
                      })
                    } catch (e) {
                      handleErrors(e, helpers.setFieldError)
                    }
                  } else {
                    helpers.setFieldError('Unknown error', 'Please contact customer support.')
                  }
                }
              }
            }}
          >
            <FormStripeInput {...CONFIG.STRIPE_CARD} />
          </Form>
        )}
      </PageLayout>
    </Page>
  )
}

const UpdatePaymentDetailsStripe: PageConfig<unknown, PathArgs> = () => (
  <StripeWrapper>
    <UpdatePaymentDetails />
  </StripeWrapper>
)

UpdatePaymentDetailsStripe.route = ROUTE

export default UpdatePaymentDetailsStripe
