import { useQuery } from '@apollo/client'

import Page from '../../../../components/Page'
import PageLayout from '../../../../components/PageLayout'
import { PageConfig } from '../../../../routing'
import { API } from '../../../../api'

import PaymentDetails from './components/PaymentDetails'
import InvoiceHistory from './components/InvoiceHistory'
import PlanDetails from './components/PlanDetails'
import BillingDetails from './components/BillingDetails'
import { MEMBERSHIP } from './queries'
import ROUTE, { PathArgs } from './route'

const Membership: PageConfig<unknown, PathArgs> = () => {
  const { data, loading } = useQuery<API.Membership_Session>(MEMBERSHIP)

  const membership = data?.session?.membership
  const hasHadMembership = !!membership
  const hasActiveMembership = data?.session?.type === API.UserType.MEMBER
  const hasTeamMembership = data?.session?.type === API.UserType.TEAM_MEMBER

  return (
    <Page title="Membership">
      <PageLayout
        title="Membership"
        loading={loading}
        subtitle={
          hasTeamMembership
            ? 'Your membership is managed by your Team admin.'
            : !hasActiveMembership
            ? "You don't currently have a membership."
            : undefined
        }
      >
        {(hasActiveMembership || hasHadMembership) && (
          <>
            {hasActiveMembership && <PlanDetails />}
            {hasActiveMembership && <PaymentDetails />}
            {hasHadMembership && <InvoiceHistory />}
            {hasActiveMembership && <BillingDetails />}
          </>
        )}
      </PageLayout>
    </Page>
  )
}

Membership.route = ROUTE

export default Membership
