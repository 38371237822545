import { useQuery } from '@apollo/client'
import { Body, Button, Lockup, VerticalSpacer } from '@superhi/design'

import { API } from '../../../../../../api'
import { Section } from '../../../../../../components/PageLayout'
import UpdatePaymentDetailsStripe from '../../../UpdatePaymentDetails'

import { SESSION } from './queries'

const PaymentDetails = () => {
  const { data, loading } = useQuery<API.MembershipSettings_PaymentDetails_Session>(SESSION)

  const plan = data?.session?.membership?.plan

  return (
    <Section
      data-component-name="payment-details"
      title="Payment details"
      loading={loading}
      buttons={
        <Button version="outline" href={UpdatePaymentDetailsStripe.route.path()}>
          Update payment details
        </Button>
      }
    >
      <VerticalSpacer amount={{ small: 4, medium: 4, large: 4 }}>
        <Body level="3">
          <strong>**** **** **** {plan?.paymentDetails.lastFourDigits || '****'}</strong>
        </Body>
        <Body level="3">
          Expires{' '}
          {plan
            ? `${plan.paymentDetails.expiryMonth.toString().padStart(2, '0')}/${
                plan.paymentDetails.expiryYear.toString().split('20')[1]
              }`
            : ''}
        </Body>
      </VerticalSpacer>
    </Section>
  )
}

export default PaymentDetails
