import { useRouter } from 'next/router'
import React from 'react'

import events from '../../../../../events'

const useTrackPage = () => {
  const router = useRouter()

  React.useEffect(() => {
    events.pageViewed()
  }, [router.asPath])

  return null
}

export default useTrackPage
