import { gql } from '@apollo/client'

export const UPDATE = gql`
  mutation Settings_UpdatePassword_PasswordUpdate(
    $password: String!
    $passwordConfirmation: String!
  ) {
    sessionPasswordUpdate(password: $password, passwordConfirmation: $passwordConfirmation) {
      id
    }
  }
`
