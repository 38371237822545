import { useQuery } from '@apollo/client'
import { Body, Button, getImgProps, PersonChip, TextButton } from '@superhi/design'

import Page from '../../../../components/Page'
import PageLayout, { Section } from '../../../../components/PageLayout'
import { PageConfig } from '../../../../routing'
import { API } from '../../../../api'
import UpdateDetails from '../UpdateDetails'
import UpdatePassword from '../UpdatePassword'
import { HelpScout } from '../../../../services'

import { SETTINGS } from './queries'
import ROUTE, { PathArgs } from './route'

const Settings: PageConfig<unknown, PathArgs> = () => {
  const { data, loading } = useQuery<API.Settings_Session>(SETTINGS)

  const session = data?.session

  return (
    <Page title="Settings">
      <PageLayout title="Settings" loading={loading}>
        {session && (
          <>
            <Section
              buttons={
                <>
                  <Button href={UpdateDetails.route.path()} version="outline">
                    Update personal details
                  </Button>
                  <Button href={UpdatePassword.route.path()} version="outline">
                    Change password
                  </Button>
                </>
              }
            >
              <PersonChip
                level="2"
                name={session.name || 'No name'}
                jobTitle={session.email}
                image={
                  session.avatar
                    ? getImgProps(session.avatar.url, {
                        small: 5,
                        medium: 5,
                        large: 5,
                        xlarge: 5,
                      })
                    : { src: '' }
                }
              />
            </Section>

            <Section title="Delete your account">
              <Body level="3">
                If you&apos;d like us to remove your details from our records, please{' '}
                <TextButton color="BLUE_50" hoverColor="BLUE_60" onClick={() => HelpScout.open()}>
                  contact customer support
                </TextButton>{' '}
                and we&apos;ll be happy to do this for you.
              </Body>
            </Section>
          </>
        )}
      </PageLayout>
    </Page>
  )
}

Settings.route = ROUTE

export default Settings
