import { setContext } from 'apollo-link-context'
import fetch from 'isomorphic-unfetch'
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'

// import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { API_DOMAIN, API_VERSION } from '../config'

import authTokenCookie from './auth/cookie'
import * as API from './types'

const IS_BROWSER = (process as any).browser

let client: ApolloClient<any> | null = null

const uri = `${API_DOMAIN}/v${API_VERSION}/graphql`

if (!IS_BROWSER) {
  ;(global as any).fetch = fetch
}

const createAuthLink = (): any =>
  setContext((_, { headers = {} }) => {
    const authToken = authTokenCookie.get()
    const authtokenHeader = authToken
      ? {
          Authorization: `Bearer ${authToken}`,
        }
      : {}

    return {
      headers: {
        ...headers,
        ...authtokenHeader,
        'superhi-ci-token': process.env.SUPER_HI_CI_TOKEN,
      },
    }
  })

const errorLink = onError(({ operation, response, graphQLErrors = [] }) => {
  graphQLErrors.forEach((e) => {
    const error = e as unknown as { details: string; message: string }

    switch (error.details) {
      case 'base': {
        if (error.message === 'Access denied') {
        }
        break
      }
    }
  })
})

const abortController = new AbortController()
const httpLink = createHttpLink({
  uri,
  fetch,
  fetchOptions: {
    signal: abortController.signal,
  },
})

if (!IS_BROWSER) {
  ;(global as any).fetch = fetch
}

const createClient = (initialState: any, ssrMode = false) => {
  const cache = new InMemoryCache({
    typePolicies: {
      Money: {
        merge: true,
      },
      UserMembershipPlan: {
        merge: true,
      },
      BasketDiscount: {
        merge: true,
      },
      ProductCourse: {
        fields: {
          image: {
            merge: true,
          },
        },
      },
    },
  })

  client = new ApolloClient({
    link: createAuthLink().concat(errorLink).concat(httpLink),
    cache: cache.restore(initialState),
    ssrMode: false,
    connectToDevTools: IS_BROWSER,
  })

  return client
}

export const initApollo = ({ initialState = {} }: { initialState?: object }) => {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!IS_BROWSER) {
    return createClient(initialState, true)
  }

  // Reuse client on the client-side
  if (!client) {
    client = createClient(initialState)
  }

  return client
}

export { API }
export default initApollo
