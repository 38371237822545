import { useMutation } from '@apollo/client'
import { Button, Buttons, Form, FormTextInput } from '@superhi/design'

import { API } from '../../../../api'
import Page from '../../../../components/Page'
import PageLayout from '../../../../components/PageLayout'
import { PageConfig } from '../../../../routing'
import { handleErrors } from '../../../../utils'
import Settings from '../View'

import * as CONFIG from './config'
import { UPDATE } from './mutations'
import ROUTE, { PathArgs } from './route'

type FormValues = API.Settings_UpdatePassword_PasswordUpdateVariables

const UpdatePassword: PageConfig<unknown, PathArgs> = () => {
  const [updateDetails, updateDetailsResult] = useMutation<
    API.Settings_UpdatePassword_PasswordUpdate,
    API.Settings_UpdatePassword_PasswordUpdateVariables
  >(UPDATE)

  const hasUpdated =
    updateDetailsResult.called && !updateDetailsResult.loading && !updateDetailsResult.error

  return (
    <Page title="Change your password">
      <PageLayout
        title={!hasUpdated ? 'Change your password' : 'Your password has been changed!'}
        backUrl={Settings.route.path()}
        footer={
          hasUpdated && (
            <Buttons align="center">
              <Button href={Settings.route.path()}>Done</Button>
            </Buttons>
          )
        }
      >
        {!hasUpdated && (
          <Form<FormValues>
            name="settings-update-password"
            initialValues={{
              password: '',
              passwordConfirmation: '',
            }}
            submitButtonText="Change password"
            submitButtonAlign="center"
            onSubmit={async (values, helpers) => {
              try {
                await updateDetails({
                  variables: values,
                })
              } catch (e) {
                handleErrors(e, helpers.setFieldError)
              }
            }}
          >
            <Form.Rows>
              <FormTextInput {...CONFIG.PASSWORD} />
              <FormTextInput {...CONFIG.PASSWORD_CONFIRMATION} />
            </Form.Rows>
          </Form>
        )}
      </PageLayout>
    </Page>
  )
}

UpdatePassword.route = ROUTE

export default UpdatePassword
