import { Body, Link, Lockup, Price, TextVerticalSpacer } from '@superhi/design'
import { formatDateOnly } from '@superhi/design/dist/cjs/components/utils/DateTime'
import React from 'react'

import { API } from '../../../../../../../../api'

import { Wrapper } from './styles'

type Props = {
  title?: string
  dateTime: string
  receiptUrl?: string | null
  total: {
    currencyCode: API.CurrencyCodeV1 | API.CurrencyCode
    value: number
  }
  version?: API.OrderVersion
}

const InvoiceItem: React.FC<Props> = ({ title, dateTime, receiptUrl, total, version }) => (
  <Wrapper>
    <div>
      <Body level="3" inline>
        {formatDateOnly(dateTime)}
      </Body>
      {title && (
        <Body level="3" color="GREY_70">
          {title}
        </Body>
      )}
    </div>

    <div>
      {total.value === 0 && version && version === API.OrderVersion.ONE_ZERO ? null : (
        <Body level="3">
          <Price currencyCode={total.currencyCode}>{total.value}</Price>
        </Body>
      )}
    </div>

    <div>
      <Body level="3" inline color={!receiptUrl ? 'GREY_70' : undefined}>
        {receiptUrl ? (
          <Link isExternal href={receiptUrl}>
            View
          </Link>
        ) : (
          <>View</>
        )}
      </Body>
    </div>
  </Wrapper>
)

export default InvoiceItem
