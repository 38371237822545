import { gql } from '@apollo/client'

export const SESSION = gql`
  query RouteSession {
    session {
      id
      token
    }
  }
`
