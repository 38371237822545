import { gql } from '@apollo/client'

import { FRAGMENT } from '../View/components/BillingDetails/queries'

export const SESSION = gql`
  query MembershipSettings_UpdateBillingDetails {
    session {
      id
      membership {
        ...MembershipSettings_BillingDetails_Membership
      }
    }
  }

  ${FRAGMENT}
`
