import React from 'react'

import SEO from '../SEO'

type Props = React.ComponentProps<typeof SEO> & {}

const Page: React.FC<Props> = ({ children, ...seoProps }) => (
  <>
    <SEO {...seoProps} />
    {children}
  </>
)

export default Page
