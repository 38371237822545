import { useQuery } from '@apollo/client'
import { Body } from '@superhi/design'

import { API } from '../../../../../../api'
import { Section } from '../../../../../../components/PageLayout'

import { SESSION } from './queries'
import { Invoices } from './styles'
import InvoiceItem from './components/InvoiceItem'

const InvoiceHistory = () => {
  const { data, loading } = useQuery<API.MembershipSettings_InvoiceHistory_Session>(SESSION)

  const membership = data?.session?.membership

  return (
    <Section title="Invoice history" loading={loading}>
      {(membership?.invoices?.length || 0) > 0 ? (
        <Invoices>
          {membership?.invoices?.map((invoice) => (
            <InvoiceItem
              key={invoice.id}
              dateTime={invoice.createdAt}
              receiptUrl={invoice.receiptUrl}
              total={invoice.total}
            />
          ))}
        </Invoices>
      ) : (
        <Body level="3">You don&apos;t have any invoices.</Body>
      )}
    </Section>
  )
}

export default InvoiceHistory
