import { gql } from '@apollo/client'

export const ORDERS = gql`
  query OrderHistory {
    session {
      id
      orders(perPage: 1000, sort: { direction: DESC, key: INSERTED_AT }) {
        nodes {
          id
          lineItems {
            nodes {
              id
              variant {
                id
                title
              }
              quantity
            }
          }
          version
          receiptUrl
          total {
            value
            currencyCode
          }
          insertedAt
        }
      }
    }
  }
`
