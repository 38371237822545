import { gql } from '@apollo/client'

import { FRAGMENT } from '../View/queries'

export const SETTINGS = gql`
  query Settings_UpdateDetails_Session {
    session {
      ...Settings_UserSettings
    }
  }

  ${FRAGMENT}
`
