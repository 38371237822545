import { FormCheckboxInput, FormTextInput } from '@superhi/design'
import { FieldConfig, FormSelectInputCountryCode } from '@superhi/utils'
import { boolean, object, ObjectSchemaDefinition, string } from 'yup'

import { API } from '../../../../api'

import FormTaxIdTypeInput from './components/FormTaxIdTypeInput'
import { FormValues } from './types'

const HAS_BILLING_DETAILS: FieldConfig<typeof FormCheckboxInput> = {
  name: 'hasBillingDetails',
  ariaLabel: 'Has billing details',
  children: 'I need to add extra billing details',
  required: false,
}

const HAS_BILLING_DETAILS_CONTACT: FieldConfig<typeof FormCheckboxInput> = {
  name: 'hasBillingDetailsContact',
  ariaLabel: 'Has billing details contact',
  children: 'Contact',
  required: false,
}

const HAS_BILLING_DETAILS_ADDRESS: FieldConfig<typeof FormCheckboxInput> = {
  name: 'hasBillingDetailsAddress',
  ariaLabel: 'Has billing details address',
  children: 'Address',
  required: false,
}

const HAS_BILLING_DETAILS_TAX_ID: FieldConfig<typeof FormCheckboxInput> = {
  name: 'hasBillingDetailsTaxId',
  ariaLabel: 'Has billing details tax ID',
  children: 'Tax ID',
  required: false,
}

export const BILLING_DETAILS_CONTACT_NAME: FieldConfig<typeof FormTextInput> = {
  name: 'billingDetailsContact.name',
  placeholder: 'Name',
  ariaLabel: 'Name',
  required: false,
}

const BILLING_DETAILS_CONTACT_EMAIL: FieldConfig<typeof FormTextInput> = {
  name: 'billingDetailsContact.email',
  placeholder: 'Email',
  ariaLabel: 'Email',
  required: false,
}

const BILLING_DETAILS_CONTACT = {
  NAME: BILLING_DETAILS_CONTACT_NAME,
  EMAIL: BILLING_DETAILS_CONTACT_EMAIL,
}

const BILLING_DETAILS_ADDRESS_LINE_1: FieldConfig<typeof FormTextInput> = {
  name: 'billingDetailsAddress.line1',
  placeholder: 'Address line 1',
  ariaLabel: 'Address line 1',
  required: true,
}

const BILLING_DETAILS_ADDRESS_LINE_2: FieldConfig<typeof FormTextInput> = {
  name: 'billingDetailsAddress.line2',
  placeholder: 'Address line 2',
  ariaLabel: 'Address line 2',
  required: false,
}

const BILLING_DETAILS_ADDRESS_CITY: FieldConfig<typeof FormTextInput> = {
  name: 'billingDetailsAddress.city',
  placeholder: 'City or town',
  ariaLabel: 'City or town',
  required: true,
}

const BILLING_DETAILS_ADDRESS_STATE: FieldConfig<typeof FormTextInput> = {
  name: 'billingDetailsAddress.state',
  placeholder: 'State, province or region',
  ariaLabel: 'State, province or region',
  required: true,
}

const BILLING_DETAILS_ADDRESS_POSTAL_CODE: FieldConfig<typeof FormTextInput> = {
  name: 'billingDetailsAddress.postalCode',
  placeholder: 'Postal or zip code',
  ariaLabel: 'Postal or zip code',
  required: true,
}

const BILLING_DETAILS_ADDRESS_COUNTRY: FieldConfig<typeof FormSelectInputCountryCode> = {
  name: 'billingDetailsAddress.country',
  placeholder: 'Country',
  ariaLabel: 'Country',
  required: true,
}

const BILLING_DETAILS_ADDRESS = {
  LINE_1: BILLING_DETAILS_ADDRESS_LINE_1,
  LINE_2: BILLING_DETAILS_ADDRESS_LINE_2,
  CITY: BILLING_DETAILS_ADDRESS_CITY,
  STATE: BILLING_DETAILS_ADDRESS_STATE,
  POSTAL_CODE: BILLING_DETAILS_ADDRESS_POSTAL_CODE,
  COUNTRY: BILLING_DETAILS_ADDRESS_COUNTRY,
}

const BILLING_DETAILS_TAX_ID_TYPE: FieldConfig<typeof FormTaxIdTypeInput> = {
  name: 'billingDetailsTaxId.type',
  placeholder: 'Choose one',
  ariaLabel: 'Tax ID type',
  required: true,
}

const BILLING_DETAILS_TAX_ID_VALUE: FieldConfig<typeof FormTextInput> = {
  name: 'billingDetailsTaxId.value',
  placeholder: 'Tax ID',
  ariaLabel: 'Tax ID',
  required: true,
}

const BILLING_DETAILS_TAX_ID = {
  TYPE: BILLING_DETAILS_TAX_ID_TYPE,
  VALUE: BILLING_DETAILS_TAX_ID_VALUE,
}

export const FIELD_CONFIGS = {
  HAS_BILLING_DETAILS,
  HAS_BILLING_DETAILS_CONTACT,
  HAS_BILLING_DETAILS_ADDRESS,
  HAS_BILLING_DETAILS_TAX_ID,
  BILLING_DETAILS_CONTACT,
  BILLING_DETAILS_ADDRESS,
  BILLING_DETAILS_TAX_ID,
}

/////////////////////

const contact = (required: boolean) =>
  object({
    name: string().required(),
    email: string().email('This must be a valid email.').required(),
  }).strip(!required)

const address = (required: boolean) =>
  object({
    line1: string().required(),
    line2: string(),
    city: string().required(),
    state: string().required(),
    postalCode: string().required(),
    country: string().required(),
  }).strip(!required)

const taxId = (required: boolean) =>
  object({
    type: string().oneOf(Object.values(API.TaxIdType)).required(),
    value: string().required(),
  }).strip(!required)

export const VALIDATION_SCHEMA: ObjectSchemaDefinition<FormValues> = {
  hasBillingDetails: boolean(),

  hasBillingDetailsContact: boolean(),
  billingDetailsContact: contact(false).when(
    [FIELD_CONFIGS.HAS_BILLING_DETAILS_CONTACT.name, FIELD_CONFIGS.HAS_BILLING_DETAILS.name],
    {
      is: true,
      then: contact(true).required(),
      otherwise: contact(false),
    },
  ),

  hasBillingDetailsAddress: boolean(),
  billingDetailsAddress: address(false).when(
    [FIELD_CONFIGS.HAS_BILLING_DETAILS_ADDRESS.name, FIELD_CONFIGS.HAS_BILLING_DETAILS.name],
    {
      is: true,
      then: address(true).required(),
      otherwise: address(false),
    },
  ),

  hasBillingDetailsTaxId: boolean(),
  billingDetailsTaxId: taxId(false).when(
    [FIELD_CONFIGS.HAS_BILLING_DETAILS_TAX_ID.name, FIELD_CONFIGS.HAS_BILLING_DETAILS.name],
    {
      is: true,
      then: taxId(true).required(),
      otherwise: taxId(false),
    },
  ),
}
