import { gql } from '@apollo/client';

export const FRAGMENT = gql`
  fragment MembershipSettings_PaymentDetails_Membership on UserMembership {
    id
    plan {
      paymentDetails {
        lastFourDigits
        expiryMonth
        expiryYear
      }
    }
  }
`;

export const SESSION = gql`
  query MembershipSettings_PaymentDetails_Session {
    session {
      id
      membership {
        ...MembershipSettings_PaymentDetails_Membership
      }
    }
  }

  ${FRAGMENT}
`;
