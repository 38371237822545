import React from 'react'

import PageLayout from '../../components/PageLayout'
import Page from '../../components/Page'

type Props = {}

const Loading: React.FC<Props> = () => (
  <Page>
    <PageLayout title="Loading..."></PageLayout>
  </Page>
)

export default Loading
