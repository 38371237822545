import { RouteConfig } from '../../../../routing/types'

export type PathArgs = unknown[]

const ROUTE: RouteConfig<PathArgs> = {
  name: 'membership',
  path: () => '/membership',
  type: 'private',
}

export default ROUTE
