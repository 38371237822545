import { Message, TextButton } from '@superhi/design'

import { HelpScout } from '../../services'

const ErrorMessageHelpScout = () => (
  <Message color="RED_10" level="3">
    An unknown error ocurred. Please{' '}
    <TextButton onClick={() => HelpScout.open()} color="BLUE_50" hoverColor="BLUE_60">
      contact customer support
    </TextButton>
    .
  </Message>
)

export default ErrorMessageHelpScout
