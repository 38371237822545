import { theme } from '@superhi/design'
import styled from 'styled-components'

export const Invoices = styled.div`
  > *:not(:last-child) {
    border-bottom: 1px solid ${theme.colors.GREY_50};
    margin-bottom: 16px;
    padding-bottom: 16px;
  }
`
