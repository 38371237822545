import { Buttons as ButtonsComponent } from '@superhi/design'
import styled from 'styled-components'

export const CancelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
`

export const Buttons = styled(ButtonsComponent)`
  width: auto;
`
