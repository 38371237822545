import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js/pure'

import { STRIPE_API_KEY } from '../../../../../../config'

let stripePromise: any

export const getStripe = async () => {
  if (!stripePromise) {
    stripePromise = await loadStripe(STRIPE_API_KEY!)
  }

  return stripePromise
}

const StripeWrapper: React.FC = ({ children }) => (
  <Elements
    stripe={getStripe()}
    options={{
      fonts: [
        {
          src: 'url(//superhi-assets.s3-us-west-1.amazonaws.com/fonts/gt-america/Standard/Webfonts/GT-America-Regular.woff)',
          family: 'GT America',
          weight: '400',
          style: 'normal',
        },
      ],
    }}
  >
    {children}
  </Elements>
)

export default StripeWrapper
