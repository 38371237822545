import { gql } from '@apollo/client'

import { FRAGMENT } from './queries'

export const UPDATE_MEMBERSHIP = gql`
  mutation Membership_UpdatePlan_mutation($interval: StripePriceRecurringInterval!) {
    userMembershipPlanUpdate(interval: $interval) {
      ...MembershipUpdatePlan
    }
  }

  ${FRAGMENT}
`
