import { gql } from '@apollo/client'

export const FRAGMENT = gql`
  fragment MembershipCancelPlan on Session {
    id
    membership {
      id
      plan {
        product {
          title
        }
        renewalDate
        currentPrice {
          id
          recurring {
            interval
          }
        }
        nextPrice {
          id
          recurring {
            interval
          }
        }
        nextPayment {
          value
          currencyCode
        }
      }
    }
  }
`

export const PLAN = gql`
  query Membership_CancelPlan {
    session {
      ...MembershipCancelPlan
    }
  }

  ${FRAGMENT}
`
