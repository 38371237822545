import React from 'react'
import { FontHeadComponent, ThemeProvider } from '@superhi/design'
import { ApolloProvider } from '@apollo/client'
import { AppProps } from 'next/app'
import { NextPage } from 'next'
import Head from 'next/head'

import LinkNext from '../LinkNext'
import initApollo from '../../api'
import SEO from '../../components/SEO'
import { PageConfig } from '../../routing/types'
import Route from '../../routing/Route'

import Layout from './components/Layout'
import Utils from './components/Utils'

const client = initApollo({})

type Props = AppProps & {
  Component: NextPage & PageConfig<unknown, unknown[]>
}

const App: React.FC<Props> = ({ Component, pageProps }) => {
  const routeType = Component?.route?.type

  return (
    <>
      <Head>
        <FontHeadComponent />
      </Head>

      <ApolloProvider client={client}>
        <ThemeProvider LinkComponent={LinkNext}>
          <Utils />

          <SEO />

          <Layout>
            <Route type={routeType}>
              <Component {...pageProps} />
            </Route>
          </Layout>
        </ThemeProvider>
      </ApolloProvider>
    </>
  )
}

export default App
