import { gql } from '@apollo/client';

export const USER_FRAGMENT = gql`
  fragment SessionSiteHeaderComponent on Session {
    id
    username
    email
    avatar {
      url
    }
  }
`;
