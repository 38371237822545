import { gql } from '@apollo/client'

import { USER_FRAGMENT } from './fragments'

export const SESSION = gql`
  query SiteHeaderComponentSession {
    session {
      ...SessionSiteHeaderComponent
    }
  }

  ${USER_FRAGMENT}
`
