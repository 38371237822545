import { gql } from '@apollo/client'

import { FRAGMENT } from '../View/components/PaymentDetails/queries'

export const UPDATE = gql`
  mutation Membership_UpdatePaymentDetails($stripePaymentMethodId: String!) {
    membershipPaymentDetailsUpdate(stripePaymentMethodId: $stripePaymentMethodId) {
      id
      membership {
        ...MembershipSettings_PaymentDetails_Membership
      }
    }
  }

  ${FRAGMENT}
`
