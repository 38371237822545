import { useMutation, useQuery } from '@apollo/client'
import { Button, Form, FormTextInput, Buttons } from '@superhi/design'

import { API } from '../../../../api'
import { PageConfig } from '../../../../routing'
import Page from '../../../../components/Page'
import PageLayout, { SPACING } from '../../../../components/PageLayout'
import Settings from '../View'
import { handleErrors } from '../../../../utils'

import { SETTINGS } from './queries'
import { UPDATE } from './mutations'
import * as CONFIG from './config'
import ROUTE, { PathArgs } from './route'

type FormValues = API.Settings_UpdateDetails_InfoUpdateVariables

const UpdateDetails: PageConfig<unknown, PathArgs> = () => {
  const { data, loading } = useQuery<API.Settings_UpdateDetails_Session>(SETTINGS)

  const session = data?.session

  const [updateDetails, updateDetailsResult] =
    useMutation<API.Settings_UpdateDetails_InfoUpdate>(UPDATE)

  const hasUpdated =
    updateDetailsResult.called && !updateDetailsResult.loading && !updateDetailsResult.error

  return (
    <Page title="Update your personal details">
      <PageLayout
        title={
          !hasUpdated ? 'Update your personal details' : 'Your personal details have been updated!'
        }
        loading={loading}
        backUrl={Settings.route.path()}
        footer={
          hasUpdated && (
            <Buttons align="center">
              <Button href={Settings.route.path()}>Done</Button>
            </Buttons>
          )
        }
      >
        {!hasUpdated && session && (
          <Form<FormValues>
            name="settings-update-details"
            initialValues={{
              name: session.name || '',
              email: session.email,
              username: session.username,
            }}
            submitButtonText="Update personal details"
            submitButtonAlign="center"
            spacing={SPACING}
            onSubmit={async (values, helpers) => {
              try {
                await updateDetails({
                  variables: values,
                })
              } catch (e) {
                handleErrors(e, helpers.setFieldError)
              }
            }}
          >
            <Form.Rows>
              <FormTextInput {...CONFIG.NAME} />
              <FormTextInput {...CONFIG.USERNAME} />
              <FormTextInput {...CONFIG.EMAIL} />
            </Form.Rows>
          </Form>
        )}
      </PageLayout>
    </Page>
  )
}

UpdateDetails.route = ROUTE

export default UpdateDetails
