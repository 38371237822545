export const API_DOMAIN = process.env.NEXT_PUBLIC_API_DOMAIN!
export const API_VERSION = process.env.NEXT_PUBLIC_API_VERSION!

export const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL! || process.env.NEXT_PUBLIC_VERCEL_URL!
export const MARKETING_SITE_URL = process.env.NEXT_PUBLIC_MARKETING_SITE_URL!
export const DASHBOARD_URL = process.env.NEXT_PUBLIC_DASHBOARD_URL!
export const SUPERLIBRARY_URL = process.env.NEXT_PUBLIC_SUPERLIBRARY_URL!
export const EDITOR_URL = process.env.NEXT_PUBLIC_EDITOR_URL!

export const PAYPAL_CLIENT_ID = process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID!
export const STRIPE_API_KEY = process.env.NEXT_PUBLIC_STRIPE_API_KEY!

export const HELPSCOUT_BEACON_ID = process.env.NEXT_PUBLIC_HELPSCOUT_BEACON_ID!
export const HEAP_APP_ID = process.env.NEXT_PUBLIC_HEAP_APP_ID!
export const FACEBOOK_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID!
export const GOOGLE_ANALYTICS_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID!

export const IS_CYPRESS = process.env.NEXT_PUBLIC_IS_CYPRESS!
