import { Link, theme, VerticalSpacer } from '@superhi/design'
import styled from 'styled-components'

export const Wrapper = styled(VerticalSpacer).attrs({
  amount: { small: 32, medium: 32, large: 32 },
})`
  position: relative;
`

export const Back = styled(Link)`
  position: absolute;
  width: 48px;
  height: 48px;
  top: -40px;
  left: -24px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${theme.breakpoints.medium} {
    top: -48px;
    left: -32px;
    width: 56px;
    height: 56px;
  }

  @media ${theme.breakpoints.large} {
    top: -48px;
    left: -48px;
    width: 56px;
    height: 56px;
  }
`
