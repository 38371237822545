import { createErrorMap, SuperHiFormError } from '@superhi/utils'

export const handleErrors = (
  e: any,
  setFieldError: (field: string, message: string) => void,
  fieldNameMap?: Parameters<typeof createErrorMap>[1],
) => {
  const errors = Object.entries(createErrorMap(e, fieldNameMap))

  if (errors.length) {
    errors.forEach(([name, error]) => {
      setFieldError(name, error as string)
    })
  } else {
    setFieldError('__form', SuperHiFormError.defaultErrorMessage)
  }
}
