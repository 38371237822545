import { FacebookPixel, GoogleAnalytics } from '../../../services'

export const pageViewed = () => {
  try {
    // google analytics
    GoogleAnalytics.track('page_view', {})

    // facebook pixel
    FacebookPixel.track('PageView', {})
  } catch (e) {}
}
