const name: 'includeAuthToken' = 'includeAuthToken'

const get = (): boolean => (sessionStorage.getItem(name) === 'yes' ? true : false)

const set = (include: boolean) => sessionStorage.setItem(name, include ? 'yes' : 'no')

const remove = () => sessionStorage.removeItem(name)

const includeAuthTokenUtils = {
  name,
  get,
  set,
  remove,
}

export default includeAuthTokenUtils
