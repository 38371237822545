import React from 'react'
import { Card, Grid, SuperHiLogo, UserDropdownMenu } from '@superhi/design'

import { utils as redirectExternalUtils } from '../../../RedirectExternal'
import includeAuthTokenUtils from '../../../../api/auth/includeAuthToken'

import {
  Wrapper,
  Header,
  Global,
  CardWrapper,
  CardContent,
  UserDropdownMenuWrapper,
} from './styles'
import { useSiteHeaderUserProps } from './utils'

type Props = {}

const Layout: React.FC<Props> = ({ children }) => {
  const userProps = useSiteHeaderUserProps()

  React.useEffect(() => {
    const url = new URL(window.location.href)
    const redirectExternal = url.searchParams.get(redirectExternalUtils.name)
    const includeAuthToken = url.searchParams.get(includeAuthTokenUtils.name)

    if (redirectExternal) {
      redirectExternalUtils.set(redirectExternal)
    }

    if (includeAuthToken) {
      includeAuthTokenUtils.set(true)
    }
  }, [])

  return (
    <>
      <Global />
      <Grid>
        <Grid.Cell span="full">
          <Wrapper>
            <Header>
              <SuperHiLogo color="BLUE_50" height={{ small: 24, medium: 28, large: 32 }} />
              {userProps && (
                <UserDropdownMenuWrapper data-component-name="UserDropdownMenu">
                  <UserDropdownMenu
                    user={userProps.user}
                    items={userProps.items}
                    closeOn={location.pathname}
                  />
                </UserDropdownMenuWrapper>
              )}
            </Header>

            <CardWrapper>
              <Card level="1">
                <CardContent>{children}</CardContent>
              </Card>
            </CardWrapper>
          </Wrapper>
        </Grid.Cell>
      </Grid>
    </>
  )
}

export default Layout
