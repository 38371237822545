import { gql } from '@apollo/client'

import { FRAGMENT } from '../View/queries'

export const UPDATE = gql`
  mutation Settings_UpdateDetails_InfoUpdate($name: String!, $email: String!, $username: String!) {
    sessionInfoUpdate(email: $email, name: $name, username: $username) {
      id
      ...Settings_UserSettings
    }
  }

  ${FRAGMENT}
`
