import { FormTextInput } from '@superhi/design'
import { FieldConfig } from '@superhi/utils'

export const NAME: FieldConfig<typeof FormTextInput> = {
  name: 'name',
  label: 'Full name',
  ariaLabel: 'Full name',
  placeholder: 'e.g. Rik Lomas',
  required: true,
}

export const EMAIL: FieldConfig<typeof FormTextInput> = {
  name: 'email',
  label: 'Email',
  ariaLabel: 'Email',
  placeholder: 'e.g. rik@superhi.com',
  required: true,
}

export const USERNAME: FieldConfig<typeof FormTextInput> = {
  name: 'username',
  label: 'Username',
  ariaLabel: 'Username',
  placeholder: 'e.g. riklomas',
  required: true,
}
