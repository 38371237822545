/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Auth_Session
// ====================================================

export interface Auth_Session_session_membership {
  __typename: "UserMembership";
  id: string;
  status: MembershipStatus;
}

export interface Auth_Session_session {
  __typename: "Session";
  id: string;
  membership: Auth_Session_session_membership | null;
}

export interface Auth_Session {
  /**
   * Get the session of the current user
   */
  session: Auth_Session_session | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SiteHeaderComponentSession
// ====================================================

export interface SiteHeaderComponentSession_session_avatar {
  __typename: "Image";
  url: string;
}

export interface SiteHeaderComponentSession_session {
  __typename: "Session";
  id: string;
  username: string;
  email: string;
  avatar: SiteHeaderComponentSession_session_avatar | null;
}

export interface SiteHeaderComponentSession {
  /**
   * Get the session of the current user
   */
  session: SiteHeaderComponentSession_session | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UseTrackIdentity
// ====================================================

export interface UseTrackIdentity_session {
  __typename: "Session";
  id: string;
}

export interface UseTrackIdentity {
  /**
   * Get the session of the current user
   */
  session: UseTrackIdentity_session | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UseEvents_LoggedIn_Session
// ====================================================

export interface UseEvents_LoggedIn_Session_session_team {
  __typename: "Team";
  id: string;
}

export interface UseEvents_LoggedIn_Session_session_membership {
  __typename: "UserMembership";
  id: string;
  status: MembershipStatus;
}

export interface UseEvents_LoggedIn_Session_session {
  __typename: "Session";
  id: string;
  name: string | null;
  email: string;
  type: UserType;
  team: UseEvents_LoggedIn_Session_session_team | null;
  membership: UseEvents_LoggedIn_Session_session_membership | null;
}

export interface UseEvents_LoggedIn_Session {
  /**
   * Get the session of the current user
   */
  session: UseEvents_LoggedIn_Session_session | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LogIn_SessionCreate
// ====================================================

export interface LogIn_SessionCreate_sessionCreate_membership {
  __typename: "UserMembership";
  id: string;
  status: MembershipStatus;
}

export interface LogIn_SessionCreate_sessionCreate {
  __typename: "Session";
  token: string | null;
  id: string;
  membership: LogIn_SessionCreate_sessionCreate_membership | null;
}

export interface LogIn_SessionCreate {
  /**
   * Create a new session
   */
  sessionCreate: LogIn_SessionCreate_sessionCreate | null;
}

export interface LogIn_SessionCreateVariables {
  username: string;
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Membership_CancelPlan_mutation
// ====================================================

export interface Membership_CancelPlan_mutation_membershipCancel_membership_plan_product {
  __typename: "MembershipProduct";
  title: string;
}

export interface Membership_CancelPlan_mutation_membershipCancel_membership_plan_currentPrice_recurring {
  __typename: "StripePriceRecurring";
  interval: StripePriceRecurringInterval;
}

export interface Membership_CancelPlan_mutation_membershipCancel_membership_plan_currentPrice {
  __typename: "StripePrice";
  id: string;
  recurring: Membership_CancelPlan_mutation_membershipCancel_membership_plan_currentPrice_recurring | null;
}

export interface Membership_CancelPlan_mutation_membershipCancel_membership_plan_nextPrice_recurring {
  __typename: "StripePriceRecurring";
  interval: StripePriceRecurringInterval;
}

export interface Membership_CancelPlan_mutation_membershipCancel_membership_plan_nextPrice {
  __typename: "StripePrice";
  id: string;
  recurring: Membership_CancelPlan_mutation_membershipCancel_membership_plan_nextPrice_recurring | null;
}

export interface Membership_CancelPlan_mutation_membershipCancel_membership_plan_nextPayment {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface Membership_CancelPlan_mutation_membershipCancel_membership_plan {
  __typename: "UserMembershipPlan";
  product: Membership_CancelPlan_mutation_membershipCancel_membership_plan_product;
  renewalDate: any;
  currentPrice: Membership_CancelPlan_mutation_membershipCancel_membership_plan_currentPrice;
  nextPrice: Membership_CancelPlan_mutation_membershipCancel_membership_plan_nextPrice | null;
  nextPayment: Membership_CancelPlan_mutation_membershipCancel_membership_plan_nextPayment | null;
}

export interface Membership_CancelPlan_mutation_membershipCancel_membership {
  __typename: "UserMembership";
  id: string;
  plan: Membership_CancelPlan_mutation_membershipCancel_membership_plan | null;
}

export interface Membership_CancelPlan_mutation_membershipCancel {
  __typename: "Session";
  id: string;
  membership: Membership_CancelPlan_mutation_membershipCancel_membership | null;
}

export interface Membership_CancelPlan_mutation {
  /**
   * Membership cancel
   */
  membershipCancel: Membership_CancelPlan_mutation_membershipCancel | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Membership_CancelPlan
// ====================================================

export interface Membership_CancelPlan_session_membership_plan_product {
  __typename: "MembershipProduct";
  title: string;
}

export interface Membership_CancelPlan_session_membership_plan_currentPrice_recurring {
  __typename: "StripePriceRecurring";
  interval: StripePriceRecurringInterval;
}

export interface Membership_CancelPlan_session_membership_plan_currentPrice {
  __typename: "StripePrice";
  id: string;
  recurring: Membership_CancelPlan_session_membership_plan_currentPrice_recurring | null;
}

export interface Membership_CancelPlan_session_membership_plan_nextPrice_recurring {
  __typename: "StripePriceRecurring";
  interval: StripePriceRecurringInterval;
}

export interface Membership_CancelPlan_session_membership_plan_nextPrice {
  __typename: "StripePrice";
  id: string;
  recurring: Membership_CancelPlan_session_membership_plan_nextPrice_recurring | null;
}

export interface Membership_CancelPlan_session_membership_plan_nextPayment {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface Membership_CancelPlan_session_membership_plan {
  __typename: "UserMembershipPlan";
  product: Membership_CancelPlan_session_membership_plan_product;
  renewalDate: any;
  currentPrice: Membership_CancelPlan_session_membership_plan_currentPrice;
  nextPrice: Membership_CancelPlan_session_membership_plan_nextPrice | null;
  nextPayment: Membership_CancelPlan_session_membership_plan_nextPayment | null;
}

export interface Membership_CancelPlan_session_membership {
  __typename: "UserMembership";
  id: string;
  plan: Membership_CancelPlan_session_membership_plan | null;
}

export interface Membership_CancelPlan_session {
  __typename: "Session";
  id: string;
  membership: Membership_CancelPlan_session_membership | null;
}

export interface Membership_CancelPlan {
  /**
   * Get the session of the current user
   */
  session: Membership_CancelPlan_session | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Membership_UpdateBillingDetails
// ====================================================

export interface Membership_UpdateBillingDetails_membershipDetailsUpdate_membership_plan_billingContact {
  __typename: "MembershipBillingContact";
  name: string;
  email: string;
}

export interface Membership_UpdateBillingDetails_membershipDetailsUpdate_membership_plan_taxId {
  __typename: "TaxId";
  type: TaxIdType;
  value: string;
}

export interface Membership_UpdateBillingDetails_membershipDetailsUpdate_membership_plan_billingAddress {
  __typename: "MembershipBillingAddress";
  line1: string;
  line2: string | null;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}

export interface Membership_UpdateBillingDetails_membershipDetailsUpdate_membership_plan {
  __typename: "UserMembershipPlan";
  billingContact: Membership_UpdateBillingDetails_membershipDetailsUpdate_membership_plan_billingContact | null;
  taxId: Membership_UpdateBillingDetails_membershipDetailsUpdate_membership_plan_taxId | null;
  billingAddress: Membership_UpdateBillingDetails_membershipDetailsUpdate_membership_plan_billingAddress | null;
}

export interface Membership_UpdateBillingDetails_membershipDetailsUpdate_membership {
  __typename: "UserMembership";
  id: string;
  plan: Membership_UpdateBillingDetails_membershipDetailsUpdate_membership_plan | null;
}

export interface Membership_UpdateBillingDetails_membershipDetailsUpdate {
  __typename: "Session";
  id: string;
  membership: Membership_UpdateBillingDetails_membershipDetailsUpdate_membership | null;
}

export interface Membership_UpdateBillingDetails {
  /**
   * Membership details update
   */
  membershipDetailsUpdate: Membership_UpdateBillingDetails_membershipDetailsUpdate | null;
}

export interface Membership_UpdateBillingDetailsVariables {
  billingContact?: MembershipBillingContactInput | null;
  billingAddress?: MembershipBillingAddressInput | null;
  taxId?: TaxIdInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MembershipSettings_UpdateBillingDetails
// ====================================================

export interface MembershipSettings_UpdateBillingDetails_session_membership_plan_billingContact {
  __typename: "MembershipBillingContact";
  name: string;
  email: string;
}

export interface MembershipSettings_UpdateBillingDetails_session_membership_plan_taxId {
  __typename: "TaxId";
  type: TaxIdType;
  value: string;
}

export interface MembershipSettings_UpdateBillingDetails_session_membership_plan_billingAddress {
  __typename: "MembershipBillingAddress";
  line1: string;
  line2: string | null;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}

export interface MembershipSettings_UpdateBillingDetails_session_membership_plan {
  __typename: "UserMembershipPlan";
  billingContact: MembershipSettings_UpdateBillingDetails_session_membership_plan_billingContact | null;
  taxId: MembershipSettings_UpdateBillingDetails_session_membership_plan_taxId | null;
  billingAddress: MembershipSettings_UpdateBillingDetails_session_membership_plan_billingAddress | null;
}

export interface MembershipSettings_UpdateBillingDetails_session_membership {
  __typename: "UserMembership";
  id: string;
  plan: MembershipSettings_UpdateBillingDetails_session_membership_plan | null;
}

export interface MembershipSettings_UpdateBillingDetails_session {
  __typename: "Session";
  id: string;
  membership: MembershipSettings_UpdateBillingDetails_session_membership | null;
}

export interface MembershipSettings_UpdateBillingDetails {
  /**
   * Get the session of the current user
   */
  session: MembershipSettings_UpdateBillingDetails_session | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Membership_UpdatePaymentDetails
// ====================================================

export interface Membership_UpdatePaymentDetails_membershipPaymentDetailsUpdate_membership_plan_paymentDetails {
  __typename: "MembershipPaymentDetails";
  lastFourDigits: string;
  expiryMonth: number;
  expiryYear: number;
}

export interface Membership_UpdatePaymentDetails_membershipPaymentDetailsUpdate_membership_plan {
  __typename: "UserMembershipPlan";
  paymentDetails: Membership_UpdatePaymentDetails_membershipPaymentDetailsUpdate_membership_plan_paymentDetails;
}

export interface Membership_UpdatePaymentDetails_membershipPaymentDetailsUpdate_membership {
  __typename: "UserMembership";
  id: string;
  plan: Membership_UpdatePaymentDetails_membershipPaymentDetailsUpdate_membership_plan | null;
}

export interface Membership_UpdatePaymentDetails_membershipPaymentDetailsUpdate {
  __typename: "Session";
  id: string;
  membership: Membership_UpdatePaymentDetails_membershipPaymentDetailsUpdate_membership | null;
}

export interface Membership_UpdatePaymentDetails {
  /**
   * Membership payment details update
   */
  membershipPaymentDetailsUpdate: Membership_UpdatePaymentDetails_membershipPaymentDetailsUpdate | null;
}

export interface Membership_UpdatePaymentDetailsVariables {
  stripePaymentMethodId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Membership_UpdatePlan_mutation
// ====================================================

export interface Membership_UpdatePlan_mutation_userMembershipPlanUpdate_product {
  __typename: "MembershipProduct";
  title: string;
}

export interface Membership_UpdatePlan_mutation_userMembershipPlanUpdate_currentPayment {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface Membership_UpdatePlan_mutation_userMembershipPlanUpdate_currentPrice_recurring {
  __typename: "StripePriceRecurring";
  interval: StripePriceRecurringInterval;
}

export interface Membership_UpdatePlan_mutation_userMembershipPlanUpdate_currentPrice {
  __typename: "StripePrice";
  id: string;
  value: number;
  currencyCode: CurrencyCode;
  recurring: Membership_UpdatePlan_mutation_userMembershipPlanUpdate_currentPrice_recurring | null;
}

export interface Membership_UpdatePlan_mutation_userMembershipPlanUpdate_nextPayment {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface Membership_UpdatePlan_mutation_userMembershipPlanUpdate_nextPrice_recurring {
  __typename: "StripePriceRecurring";
  interval: StripePriceRecurringInterval;
}

export interface Membership_UpdatePlan_mutation_userMembershipPlanUpdate_nextPrice {
  __typename: "StripePrice";
  id: string;
  value: number;
  currencyCode: CurrencyCode;
  recurring: Membership_UpdatePlan_mutation_userMembershipPlanUpdate_nextPrice_recurring | null;
}

export interface Membership_UpdatePlan_mutation_userMembershipPlanUpdate {
  __typename: "UserMembershipPlan";
  product: Membership_UpdatePlan_mutation_userMembershipPlanUpdate_product;
  currentPayment: Membership_UpdatePlan_mutation_userMembershipPlanUpdate_currentPayment;
  currentPrice: Membership_UpdatePlan_mutation_userMembershipPlanUpdate_currentPrice;
  renewalDate: any;
  nextPayment: Membership_UpdatePlan_mutation_userMembershipPlanUpdate_nextPayment | null;
  nextPrice: Membership_UpdatePlan_mutation_userMembershipPlanUpdate_nextPrice | null;
}

export interface Membership_UpdatePlan_mutation {
  /**
   * User membership plan update
   */
  userMembershipPlanUpdate: Membership_UpdatePlan_mutation_userMembershipPlanUpdate | null;
}

export interface Membership_UpdatePlan_mutationVariables {
  interval: StripePriceRecurringInterval;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Membership_UpdatePlan
// ====================================================

export interface Membership_UpdatePlan_session_membership_plan_product {
  __typename: "MembershipProduct";
  title: string;
}

export interface Membership_UpdatePlan_session_membership_plan_currentPayment {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface Membership_UpdatePlan_session_membership_plan_currentPrice_recurring {
  __typename: "StripePriceRecurring";
  interval: StripePriceRecurringInterval;
}

export interface Membership_UpdatePlan_session_membership_plan_currentPrice {
  __typename: "StripePrice";
  id: string;
  value: number;
  currencyCode: CurrencyCode;
  recurring: Membership_UpdatePlan_session_membership_plan_currentPrice_recurring | null;
}

export interface Membership_UpdatePlan_session_membership_plan_nextPayment {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface Membership_UpdatePlan_session_membership_plan_nextPrice_recurring {
  __typename: "StripePriceRecurring";
  interval: StripePriceRecurringInterval;
}

export interface Membership_UpdatePlan_session_membership_plan_nextPrice {
  __typename: "StripePrice";
  id: string;
  value: number;
  currencyCode: CurrencyCode;
  recurring: Membership_UpdatePlan_session_membership_plan_nextPrice_recurring | null;
}

export interface Membership_UpdatePlan_session_membership_plan {
  __typename: "UserMembershipPlan";
  product: Membership_UpdatePlan_session_membership_plan_product;
  currentPayment: Membership_UpdatePlan_session_membership_plan_currentPayment;
  currentPrice: Membership_UpdatePlan_session_membership_plan_currentPrice;
  renewalDate: any;
  nextPayment: Membership_UpdatePlan_session_membership_plan_nextPayment | null;
  nextPrice: Membership_UpdatePlan_session_membership_plan_nextPrice | null;
}

export interface Membership_UpdatePlan_session_membership {
  __typename: "UserMembership";
  plan: Membership_UpdatePlan_session_membership_plan | null;
}

export interface Membership_UpdatePlan_session {
  __typename: "Session";
  membership: Membership_UpdatePlan_session_membership | null;
}

export interface Membership_UpdatePlan {
  /**
   * Get the session of the current user
   */
  session: Membership_UpdatePlan_session | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Membership_UpdatePlanPreview
// ====================================================

export interface Membership_UpdatePlanPreview_userMembershipPlanUpdatePreview_currentPayment {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface Membership_UpdatePlanPreview_userMembershipPlanUpdatePreview_currentPrice_recurring {
  __typename: "StripePriceRecurring";
  interval: StripePriceRecurringInterval;
}

export interface Membership_UpdatePlanPreview_userMembershipPlanUpdatePreview_currentPrice {
  __typename: "StripePrice";
  value: number;
  currencyCode: CurrencyCode;
  recurring: Membership_UpdatePlanPreview_userMembershipPlanUpdatePreview_currentPrice_recurring | null;
}

export interface Membership_UpdatePlanPreview_userMembershipPlanUpdatePreview_nextPayment {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface Membership_UpdatePlanPreview_userMembershipPlanUpdatePreview_nextPrice_recurring {
  __typename: "StripePriceRecurring";
  interval: StripePriceRecurringInterval;
}

export interface Membership_UpdatePlanPreview_userMembershipPlanUpdatePreview_nextPrice {
  __typename: "StripePrice";
  id: string;
  value: number;
  currencyCode: CurrencyCode;
  recurring: Membership_UpdatePlanPreview_userMembershipPlanUpdatePreview_nextPrice_recurring | null;
}

export interface Membership_UpdatePlanPreview_userMembershipPlanUpdatePreview {
  __typename: "UserMembershipPlanPreview";
  currentPayment: Membership_UpdatePlanPreview_userMembershipPlanUpdatePreview_currentPayment;
  currentPrice: Membership_UpdatePlanPreview_userMembershipPlanUpdatePreview_currentPrice;
  nextPayment: Membership_UpdatePlanPreview_userMembershipPlanUpdatePreview_nextPayment;
  nextPrice: Membership_UpdatePlanPreview_userMembershipPlanUpdatePreview_nextPrice;
  renewalDate: any;
}

export interface Membership_UpdatePlanPreview {
  /**
   * user membership plan update preview
   */
  userMembershipPlanUpdatePreview: Membership_UpdatePlanPreview_userMembershipPlanUpdatePreview | null;
}

export interface Membership_UpdatePlanPreviewVariables {
  interval: StripePriceRecurringInterval;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MembershipSettings_BillingDetails_Session
// ====================================================

export interface MembershipSettings_BillingDetails_Session_session_membership_plan_billingContact {
  __typename: "MembershipBillingContact";
  name: string;
  email: string;
}

export interface MembershipSettings_BillingDetails_Session_session_membership_plan_taxId {
  __typename: "TaxId";
  type: TaxIdType;
  value: string;
}

export interface MembershipSettings_BillingDetails_Session_session_membership_plan_billingAddress {
  __typename: "MembershipBillingAddress";
  line1: string;
  line2: string | null;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}

export interface MembershipSettings_BillingDetails_Session_session_membership_plan {
  __typename: "UserMembershipPlan";
  billingContact: MembershipSettings_BillingDetails_Session_session_membership_plan_billingContact | null;
  taxId: MembershipSettings_BillingDetails_Session_session_membership_plan_taxId | null;
  billingAddress: MembershipSettings_BillingDetails_Session_session_membership_plan_billingAddress | null;
}

export interface MembershipSettings_BillingDetails_Session_session_membership {
  __typename: "UserMembership";
  id: string;
  plan: MembershipSettings_BillingDetails_Session_session_membership_plan | null;
}

export interface MembershipSettings_BillingDetails_Session_session {
  __typename: "Session";
  id: string;
  membership: MembershipSettings_BillingDetails_Session_session_membership | null;
}

export interface MembershipSettings_BillingDetails_Session {
  /**
   * Get the session of the current user
   */
  session: MembershipSettings_BillingDetails_Session_session | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MembershipSettings_InvoiceHistory_Session
// ====================================================

export interface MembershipSettings_InvoiceHistory_Session_session_membership_invoices_product {
  __typename: "MembershipProduct";
  id: string;
  title: string;
}

export interface MembershipSettings_InvoiceHistory_Session_session_membership_invoices_price_recurring {
  __typename: "StripePriceRecurring";
  interval: StripePriceRecurringInterval;
  intervalCount: number;
}

export interface MembershipSettings_InvoiceHistory_Session_session_membership_invoices_price {
  __typename: "StripePrice";
  id: string;
  recurring: MembershipSettings_InvoiceHistory_Session_session_membership_invoices_price_recurring | null;
}

export interface MembershipSettings_InvoiceHistory_Session_session_membership_invoices_total {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface MembershipSettings_InvoiceHistory_Session_session_membership_invoices {
  __typename: "MembershipInvoice";
  id: string;
  product: MembershipSettings_InvoiceHistory_Session_session_membership_invoices_product;
  price: MembershipSettings_InvoiceHistory_Session_session_membership_invoices_price;
  invoiceUrl: string | null;
  receiptUrl: string | null;
  total: MembershipSettings_InvoiceHistory_Session_session_membership_invoices_total;
  createdAt: any;
}

export interface MembershipSettings_InvoiceHistory_Session_session_membership {
  __typename: "UserMembership";
  id: string;
  status: MembershipStatus;
  invoices: MembershipSettings_InvoiceHistory_Session_session_membership_invoices[] | null;
}

export interface MembershipSettings_InvoiceHistory_Session_session {
  __typename: "Session";
  id: string;
  membership: MembershipSettings_InvoiceHistory_Session_session_membership | null;
}

export interface MembershipSettings_InvoiceHistory_Session {
  /**
   * Get the session of the current user
   */
  session: MembershipSettings_InvoiceHistory_Session_session | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MembershipSettings_PaymentDetails_Session
// ====================================================

export interface MembershipSettings_PaymentDetails_Session_session_membership_plan_paymentDetails {
  __typename: "MembershipPaymentDetails";
  lastFourDigits: string;
  expiryMonth: number;
  expiryYear: number;
}

export interface MembershipSettings_PaymentDetails_Session_session_membership_plan {
  __typename: "UserMembershipPlan";
  paymentDetails: MembershipSettings_PaymentDetails_Session_session_membership_plan_paymentDetails;
}

export interface MembershipSettings_PaymentDetails_Session_session_membership {
  __typename: "UserMembership";
  id: string;
  plan: MembershipSettings_PaymentDetails_Session_session_membership_plan | null;
}

export interface MembershipSettings_PaymentDetails_Session_session {
  __typename: "Session";
  id: string;
  membership: MembershipSettings_PaymentDetails_Session_session_membership | null;
}

export interface MembershipSettings_PaymentDetails_Session {
  /**
   * Get the session of the current user
   */
  session: MembershipSettings_PaymentDetails_Session_session | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Membership_PlanDetails
// ====================================================

export interface Membership_PlanDetails_session_membership_plan_product {
  __typename: "MembershipProduct";
  title: string;
}

export interface Membership_PlanDetails_session_membership_plan_currentPayment {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface Membership_PlanDetails_session_membership_plan_currentPrice_recurring {
  __typename: "StripePriceRecurring";
  interval: StripePriceRecurringInterval;
  intervalCount: number;
}

export interface Membership_PlanDetails_session_membership_plan_currentPrice {
  __typename: "StripePrice";
  id: string;
  value: number;
  currencyCode: CurrencyCode;
  recurring: Membership_PlanDetails_session_membership_plan_currentPrice_recurring | null;
}

export interface Membership_PlanDetails_session_membership_plan_nextPayment {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface Membership_PlanDetails_session_membership_plan_nextPrice_recurring {
  __typename: "StripePriceRecurring";
  interval: StripePriceRecurringInterval;
}

export interface Membership_PlanDetails_session_membership_plan_nextPrice {
  __typename: "StripePrice";
  value: number;
  currencyCode: CurrencyCode;
  recurring: Membership_PlanDetails_session_membership_plan_nextPrice_recurring | null;
}

export interface Membership_PlanDetails_session_membership_plan {
  __typename: "UserMembershipPlan";
  product: Membership_PlanDetails_session_membership_plan_product;
  currentPayment: Membership_PlanDetails_session_membership_plan_currentPayment;
  currentPrice: Membership_PlanDetails_session_membership_plan_currentPrice;
  nextPayment: Membership_PlanDetails_session_membership_plan_nextPayment | null;
  nextPrice: Membership_PlanDetails_session_membership_plan_nextPrice | null;
  renewalDate: any;
}

export interface Membership_PlanDetails_session_membership {
  __typename: "UserMembership";
  id: string;
  plan: Membership_PlanDetails_session_membership_plan | null;
}

export interface Membership_PlanDetails_session {
  __typename: "Session";
  id: string;
  membership: Membership_PlanDetails_session_membership | null;
}

export interface Membership_PlanDetails {
  /**
   * Get the session of the current user
   */
  session: Membership_PlanDetails_session | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Membership_Session
// ====================================================

export interface Membership_Session_session_membership {
  __typename: "UserMembership";
  id: string;
  status: MembershipStatus;
}

export interface Membership_Session_session {
  __typename: "Session";
  id: string;
  type: UserType;
  membership: Membership_Session_session_membership | null;
}

export interface Membership_Session {
  /**
   * Get the session of the current user
   */
  session: Membership_Session_session | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrderHistory
// ====================================================

export interface OrderHistory_session_orders_nodes_lineItems_nodes_variant {
  __typename: "ProductVariant";
  id: string;
  title: string;
}

export interface OrderHistory_session_orders_nodes_lineItems_nodes {
  __typename: "OrderLineItem";
  id: string;
  variant: OrderHistory_session_orders_nodes_lineItems_nodes_variant;
  quantity: number;
}

export interface OrderHistory_session_orders_nodes_lineItems {
  __typename: "OrderLineItemList";
  nodes: OrderHistory_session_orders_nodes_lineItems_nodes[];
}

export interface OrderHistory_session_orders_nodes_total {
  __typename: "MoneyV1";
  value: number;
  currencyCode: CurrencyCodeV1;
}

export interface OrderHistory_session_orders_nodes {
  __typename: "Order";
  id: string;
  lineItems: OrderHistory_session_orders_nodes_lineItems | null;
  version: OrderVersion;
  receiptUrl: string | null;
  total: OrderHistory_session_orders_nodes_total;
  insertedAt: any;
}

export interface OrderHistory_session_orders {
  __typename: "OrderList";
  nodes: OrderHistory_session_orders_nodes[];
}

export interface OrderHistory_session {
  __typename: "Session";
  id: string;
  orders: OrderHistory_session_orders | null;
}

export interface OrderHistory {
  /**
   * Get the session of the current user
   */
  session: OrderHistory_session | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ForgottenPassword_ResetRequest
// ====================================================

export interface ForgottenPassword_ResetRequest {
  /**
   * Account password reset request
   */
  accountPasswordResetRequest: boolean | null;
}

export interface ForgottenPassword_ResetRequestVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ForgottenPassword_ResetUpdate
// ====================================================

export interface ForgottenPassword_ResetUpdate {
  /**
   * Account password reset update
   */
  accountPasswordResetUpdate: boolean | null;
}

export interface ForgottenPassword_ResetUpdateVariables {
  password: string;
  passwordConfirmation: string;
  passwordToken: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Settings_UpdateDetails_InfoUpdate
// ====================================================

export interface Settings_UpdateDetails_InfoUpdate_sessionInfoUpdate {
  __typename: "Session";
  id: string;
  name: string | null;
  username: string;
  email: string;
}

export interface Settings_UpdateDetails_InfoUpdate {
  /**
   * Update session info
   */
  sessionInfoUpdate: Settings_UpdateDetails_InfoUpdate_sessionInfoUpdate | null;
}

export interface Settings_UpdateDetails_InfoUpdateVariables {
  name: string;
  email: string;
  username: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Settings_UpdateDetails_Session
// ====================================================

export interface Settings_UpdateDetails_Session_session {
  __typename: "Session";
  id: string;
  name: string | null;
  username: string;
  email: string;
}

export interface Settings_UpdateDetails_Session {
  /**
   * Get the session of the current user
   */
  session: Settings_UpdateDetails_Session_session | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Settings_UpdatePassword_PasswordUpdate
// ====================================================

export interface Settings_UpdatePassword_PasswordUpdate_sessionPasswordUpdate {
  __typename: "Session";
  id: string;
}

export interface Settings_UpdatePassword_PasswordUpdate {
  /**
   * Update session password
   */
  sessionPasswordUpdate: Settings_UpdatePassword_PasswordUpdate_sessionPasswordUpdate | null;
}

export interface Settings_UpdatePassword_PasswordUpdateVariables {
  password: string;
  passwordConfirmation: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Settings_Session
// ====================================================

export interface Settings_Session_session_avatar {
  __typename: "Image";
  url: string;
}

export interface Settings_Session_session {
  __typename: "Session";
  id: string;
  name: string | null;
  username: string;
  email: string;
  avatar: Settings_Session_session_avatar | null;
}

export interface Settings_Session {
  /**
   * Get the session of the current user
   */
  session: Settings_Session_session | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SignUp_AccountRegister
// ====================================================

export interface SignUp_AccountRegister_accountRegister_membership {
  __typename: "UserMembership";
  id: string;
  status: MembershipStatus;
}

export interface SignUp_AccountRegister_accountRegister {
  __typename: "Session";
  token: string | null;
  id: string;
  membership: SignUp_AccountRegister_accountRegister_membership | null;
}

export interface SignUp_AccountRegister {
  /**
   * Account register
   */
  accountRegister: SignUp_AccountRegister_accountRegister | null;
}

export interface SignUp_AccountRegisterVariables {
  username: string;
  email: string;
  name?: string | null;
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RouteSession
// ====================================================

export interface RouteSession_session {
  __typename: "Session";
  id: string;
  token: string | null;
}

export interface RouteSession {
  /**
   * Get the session of the current user
   */
  session: RouteSession_session | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Auth_Session
// ====================================================

export interface Auth_Session_membership {
  __typename: "UserMembership";
  id: string;
  status: MembershipStatus;
}

export interface Auth_Session {
  __typename: "Session";
  id: string;
  membership: Auth_Session_membership | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SessionSiteHeaderComponent
// ====================================================

export interface SessionSiteHeaderComponent_avatar {
  __typename: "Image";
  url: string;
}

export interface SessionSiteHeaderComponent {
  __typename: "Session";
  id: string;
  username: string;
  email: string;
  avatar: SessionSiteHeaderComponent_avatar | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MembershipCancelPlan
// ====================================================

export interface MembershipCancelPlan_membership_plan_product {
  __typename: "MembershipProduct";
  title: string;
}

export interface MembershipCancelPlan_membership_plan_currentPrice_recurring {
  __typename: "StripePriceRecurring";
  interval: StripePriceRecurringInterval;
}

export interface MembershipCancelPlan_membership_plan_currentPrice {
  __typename: "StripePrice";
  id: string;
  recurring: MembershipCancelPlan_membership_plan_currentPrice_recurring | null;
}

export interface MembershipCancelPlan_membership_plan_nextPrice_recurring {
  __typename: "StripePriceRecurring";
  interval: StripePriceRecurringInterval;
}

export interface MembershipCancelPlan_membership_plan_nextPrice {
  __typename: "StripePrice";
  id: string;
  recurring: MembershipCancelPlan_membership_plan_nextPrice_recurring | null;
}

export interface MembershipCancelPlan_membership_plan_nextPayment {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface MembershipCancelPlan_membership_plan {
  __typename: "UserMembershipPlan";
  product: MembershipCancelPlan_membership_plan_product;
  renewalDate: any;
  currentPrice: MembershipCancelPlan_membership_plan_currentPrice;
  nextPrice: MembershipCancelPlan_membership_plan_nextPrice | null;
  nextPayment: MembershipCancelPlan_membership_plan_nextPayment | null;
}

export interface MembershipCancelPlan_membership {
  __typename: "UserMembership";
  id: string;
  plan: MembershipCancelPlan_membership_plan | null;
}

export interface MembershipCancelPlan {
  __typename: "Session";
  id: string;
  membership: MembershipCancelPlan_membership | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MembershipUpdatePlan
// ====================================================

export interface MembershipUpdatePlan_product {
  __typename: "MembershipProduct";
  title: string;
}

export interface MembershipUpdatePlan_currentPayment {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface MembershipUpdatePlan_currentPrice_recurring {
  __typename: "StripePriceRecurring";
  interval: StripePriceRecurringInterval;
}

export interface MembershipUpdatePlan_currentPrice {
  __typename: "StripePrice";
  id: string;
  value: number;
  currencyCode: CurrencyCode;
  recurring: MembershipUpdatePlan_currentPrice_recurring | null;
}

export interface MembershipUpdatePlan_nextPayment {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface MembershipUpdatePlan_nextPrice_recurring {
  __typename: "StripePriceRecurring";
  interval: StripePriceRecurringInterval;
}

export interface MembershipUpdatePlan_nextPrice {
  __typename: "StripePrice";
  id: string;
  value: number;
  currencyCode: CurrencyCode;
  recurring: MembershipUpdatePlan_nextPrice_recurring | null;
}

export interface MembershipUpdatePlan {
  __typename: "UserMembershipPlan";
  product: MembershipUpdatePlan_product;
  currentPayment: MembershipUpdatePlan_currentPayment;
  currentPrice: MembershipUpdatePlan_currentPrice;
  renewalDate: any;
  nextPayment: MembershipUpdatePlan_nextPayment | null;
  nextPrice: MembershipUpdatePlan_nextPrice | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MembershipSettings_BillingDetails_Membership
// ====================================================

export interface MembershipSettings_BillingDetails_Membership_plan_billingContact {
  __typename: "MembershipBillingContact";
  name: string;
  email: string;
}

export interface MembershipSettings_BillingDetails_Membership_plan_taxId {
  __typename: "TaxId";
  type: TaxIdType;
  value: string;
}

export interface MembershipSettings_BillingDetails_Membership_plan_billingAddress {
  __typename: "MembershipBillingAddress";
  line1: string;
  line2: string | null;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}

export interface MembershipSettings_BillingDetails_Membership_plan {
  __typename: "UserMembershipPlan";
  billingContact: MembershipSettings_BillingDetails_Membership_plan_billingContact | null;
  taxId: MembershipSettings_BillingDetails_Membership_plan_taxId | null;
  billingAddress: MembershipSettings_BillingDetails_Membership_plan_billingAddress | null;
}

export interface MembershipSettings_BillingDetails_Membership {
  __typename: "UserMembership";
  id: string;
  plan: MembershipSettings_BillingDetails_Membership_plan | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MembershipSettings_PaymentDetails_Membership
// ====================================================

export interface MembershipSettings_PaymentDetails_Membership_plan_paymentDetails {
  __typename: "MembershipPaymentDetails";
  lastFourDigits: string;
  expiryMonth: number;
  expiryYear: number;
}

export interface MembershipSettings_PaymentDetails_Membership_plan {
  __typename: "UserMembershipPlan";
  paymentDetails: MembershipSettings_PaymentDetails_Membership_plan_paymentDetails;
}

export interface MembershipSettings_PaymentDetails_Membership {
  __typename: "UserMembership";
  id: string;
  plan: MembershipSettings_PaymentDetails_Membership_plan | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Settings_UserSettings
// ====================================================

export interface Settings_UserSettings {
  __typename: "Session";
  id: string;
  name: string | null;
  username: string;
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Currency Code
 * 
 * The available currency codes
 */
export enum CurrencyCode {
  AUD = "AUD",
  CAD = "CAD",
  CHF = "CHF",
  DKK = "DKK",
  EUR = "EUR",
  GBP = "GBP",
  HKD = "HKD",
  JPY = "JPY",
  NOK = "NOK",
  NZD = "NZD",
  SEK = "SEK",
  SGD = "SGD",
  USD = "USD",
}

/**
 * Currency Codes for historical ordeers
 */
export enum CurrencyCodeV1 {
  ARS = "ARS",
  AUD = "AUD",
  BRL = "BRL",
  CAD = "CAD",
  CHF = "CHF",
  CZK = "CZK",
  DKK = "DKK",
  EUR = "EUR",
  GBP = "GBP",
  HKD = "HKD",
  ILS = "ILS",
  JPY = "JPY",
  KRW = "KRW",
  MXN = "MXN",
  NOK = "NOK",
  NZD = "NZD",
  PLN = "PLN",
  SEK = "SEK",
  SGD = "SGD",
  USD = "USD",
  ZAR = "ZAR",
}

/**
 * Membership status
 */
export enum MembershipStatus {
  ACTIVE = "ACTIVE",
  DEFAULTED = "DEFAULTED",
  INACTIVE = "INACTIVE",
  TRIALING = "TRIALING",
}

/**
 * Order version
 * 
 * Signifies the version number of the API that was used to create the order.
 */
export enum OrderVersion {
  ONE_ZERO = "ONE_ZERO",
  THREE_ZERO = "THREE_ZERO",
  TWO_ZERO = "TWO_ZERO",
}

/**
 * Stripe price recurring interval
 */
export enum StripePriceRecurringInterval {
  DAY = "DAY",
  MONTH = "MONTH",
  WEEK = "WEEK",
  YEAR = "YEAR",
}

/**
 * Tax type
 */
export enum TaxIdType {
  AE_TRN = "AE_TRN",
  AU_ABN = "AU_ABN",
  BR_CNPJ = "BR_CNPJ",
  BR_CPF = "BR_CPF",
  CA_BN = "CA_BN",
  CA_QST = "CA_QST",
  CH_VAT = "CH_VAT",
  CL_TIN = "CL_TIN",
  ES_CIF = "ES_CIF",
  EU_VAT = "EU_VAT",
  GB_VAT = "GB_VAT",
  HK_BR = "HK_BR",
  ID_NPWP = "ID_NPWP",
  IN_GST = "IN_GST",
  JP_CN = "JP_CN",
  JP_RN = "JP_RN",
  KR_BRN = "KR_BRN",
  LI_UID = "LI_UID",
  MX_RFC = "MX_RFC",
  MY_FRP = "MY_FRP",
  MY_ITN = "MY_ITN",
  MY_SST = "MY_SST",
  NO_VAT = "NO_VAT",
  NZ_GST = "NZ_GST",
  RU_INN = "RU_INN",
  RU_KPP = "RU_KPP",
  SA_VAT = "SA_VAT",
  SG_GST = "SG_GST",
  SG_UEN = "SG_UEN",
  TH_VAT = "TH_VAT",
  TW_VAT = "TW_VAT",
  US_EIN = "US_EIN",
  ZA_VAT = "ZA_VAT",
}

/**
 * User Type
 */
export enum UserType {
  FREE = "FREE",
  MEMBER = "MEMBER",
  STUDENT = "STUDENT",
  TEAM_MEMBER = "TEAM_MEMBER",
}

export interface MembershipBillingAddressInput {
  city: string;
  country: string;
  line1: string;
  line2?: string | null;
  postalCode: string;
  state: string;
}

export interface MembershipBillingContactInput {
  email: string;
  name: string;
}

/**
 * Tax id
 */
export interface TaxIdInput {
  type: TaxIdType;
  value: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
