import { theme } from '@superhi/design'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;

  @media ${theme.breakpoints.medium} {
    flex-wrap: nowrap;
  }

  > * {
    &:nth-child(1) {
      flex-grow: 1;
    }

    &:nth-child(2) {
      flex-shrink: 0;
      margin-left: 16px;
    }

    &:nth-child(3) {
      flex-shrink: 0;
      margin-left: 16px;
    }
  }
`
