import { gql } from '@apollo/client';

export const FRAGMENT = gql`
  fragment MembershipSettings_BillingDetails_Membership on UserMembership {
    id
    plan {
      billingContact {
        name
        email
      }
      taxId {
        type
        value
      }
      billingAddress {
        line1
        line2
        city
        state
        postalCode
        country
      }
    }
  }
`;

export const SESSION = gql`
  query MembershipSettings_BillingDetails_Session {
    session {
      id
      membership {
        ...MembershipSettings_BillingDetails_Membership
      }
    }
  }

  ${FRAGMENT}
`;
