import { anonymous } from './anonymous'
import { loggedIn } from './loggedIn'
import { loggedOut } from './loggedOut'
import { pageViewed } from './pageViewed'

export default {
  anonymous,
  loggedIn,
  loggedOut,
  pageViewed,
}
