import React from 'react'
import { FormSelectInput } from '@superhi/design'

import { API } from '../../../../../../api'

type Props = Omit<React.ComponentProps<typeof FormSelectInput>, 'options'>

const TAX_ID_TYPES: Record<API.TaxIdType, string> = {
  AE_TRN: 'United Arab Emirates TRN',
  AU_ABN: 'Australian Business Number (AU ABN)',
  BR_CNPJ: 'Brazilian CNPJ number',
  BR_CPF: 'Brazilian CPF number',
  CA_BN: 'Canadian BN',
  CA_QST: 'Canadian QST number',
  CH_VAT: 'Switzerland VAT number',
  CL_TIN: 'Chilean TIN',
  ES_CIF: 'Spanish CIF number',
  EU_VAT: 'European VAT number',
  GB_VAT: 'United Kingdom VAT number',
  HK_BR: 'Hong Kong BR number',
  ID_NPWP: 'Indonesian NPWP number',
  IN_GST: 'Indian GST number',
  JP_CN: 'Japanese Corporate Number (*Hōjin Bangō*)',
  JP_RN:
    'Japanese Registered Foreign Businesses Registration Number (*Tōroku Kokugai Jigyōsha no Tōroku Bangō*)',
  KR_BRN: 'Korean BRN',
  LI_UID: 'Liechtensteinian UID number',
  MX_RFC: 'Mexican RFC number',
  MY_FRP: 'Malaysian FRP number',
  MY_ITN: 'Malaysian ITN',
  MY_SST: 'Malaysian SST number',
  NO_VAT: 'Norwegian VAT number',
  NZ_GST: 'New Zealand GST number',
  RU_INN: 'Russian INN',
  RU_KPP: 'Russian KPP',
  SA_VAT: 'Saudi Arabia VAT',
  SG_GST: 'Singaporean GST',
  SG_UEN: 'Singaporean UEN',
  TH_VAT: 'Thai VAT',
  TW_VAT: 'Taiwanese VAT',
  US_EIN: 'United States EIN',
  ZA_VAT: 'South African VAT number',
}

const OPTIONS = Object.values(API.TaxIdType).map((value) => ({
  display: TAX_ID_TYPES[value],
  value,
}))

const FormTaxIdTypeInput: React.FC<Props> = (props) => (
  <FormSelectInput<any> {...props} options={OPTIONS} />
)

export default FormTaxIdTypeInput
