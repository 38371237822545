import { useQuery } from '@apollo/client'
import { Button, Lockup, TextVerticalSpacer } from '@superhi/design'

import { API } from '../../../../../../api'
import { Section } from '../../../../../../components/PageLayout'
import UpdateBillingDetails from '../../../UpdateBillingDetails'

import { SESSION } from './queries'

const BillingDetails = () => {
  const { data, loading } = useQuery<API.MembershipSettings_BillingDetails_Session>(SESSION)

  const plan = data?.session?.membership?.plan

  return (
    <Section
      data-component-name="billing-details"
      title="Billing details"
      loading={loading}
      buttons={
        <Button version="outline" href={UpdateBillingDetails.route.path()}>
          Update billing details
        </Button>
      }
    >
      <TextVerticalSpacer level="2">
        <div>
          <Lockup
            align="left"
            titleColor="GREY_90"
            level="5"
            title="Contact"
            subtitleMarkdown={
              plan?.billingContact
                ? `${plan.billingContact.name}<br />${plan.billingContact.email}`
                : 'Not set'
            }
            subtitleColor="GREY_70"
          />
        </div>

        <div>
          <Lockup
            align="left"
            titleColor="GREY_90"
            level="5"
            title="Address"
            subtitleMarkdown={
              plan?.billingAddress
                ? [
                    plan.billingAddress.line1,

                    plan.billingAddress.line2,

                    plan.billingAddress.city,

                    plan.billingAddress.state,

                    plan.billingAddress.postalCode,

                    plan.billingAddress.country,
                  ].join(', ')
                : 'Not set'
            }
            subtitleColor="GREY_70"
          />
        </div>
        <div>
          <Lockup
            align="left"
            titleColor="GREY_90"
            level="5"
            title="Tax ID"
            subtitleMarkdown={plan?.taxId ? `${plan.taxId.type} – ${plan.taxId.value}` : 'Not set'}
            subtitleColor="GREY_70"
          />
        </div>
      </TextVerticalSpacer>
    </Section>
  )
}

export default BillingDetails
