import { RouteConfig } from '../../routing/types'

export type PathArgs = unknown[]

const ROUTE: RouteConfig<PathArgs> = {
  name: 'login',
  path: () => '/login',
  type: 'public',
}

export default ROUTE
