import React from 'react'
import NextScript from 'next/script'

import { FACEBOOK_PIXEL_ID } from '../../config'

import { Track } from './global'

const track = (eventName: Parameters<Track>[1], data: Parameters<Track>[2]) => {
  window.fbq?.call(undefined, 'track', eventName, data)
}

const Script = () => (
  <NextScript
    id="facebook-pixel-1"
    type="text/javascript"
    dangerouslySetInnerHTML={{
      __html: `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${FACEBOOK_PIXEL_ID}');
      // fbq('track', 'PageView');
    `,
    }}
  />
)

const FacebookPixel = {
  Script,
  track,
}

export default FacebookPixel
