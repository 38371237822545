import { gql } from '@apollo/client'

export const MEMBERSHIP = gql`
  query Membership_Session {
    session {
      id
      type
      membership {
        id
        status
      }
    }
  }
`
