import React from 'react'
import { Icon, Lockup, VerticalSpacer } from '@superhi/design'

import { Wrapper, Back } from './styles'

export const SPACING: React.ComponentProps<typeof VerticalSpacer>['amount'] = {
  small: 32,
  medium: 32,
  large: 32,
}

type Props = {
  title: string
  subtitle?: string
  footer?: React.ReactNode
  backUrl?: string
  loading?: boolean
}

const PageLayout: React.FC<Props> = ({ title, subtitle, footer, backUrl, loading, children }) => (
  <Wrapper>
    {backUrl && (
      <Back href={backUrl}>
        <Icon name="arrow-left" size={{ small: 20, medium: 24, large: 24 }} />
      </Back>
    )}
    <Lockup
      level="3"
      titleColor="BLUE_50"
      title={title}
      subtitle={loading ? 'Loading...' : subtitle}
    />

    {!loading && (children || footer) && (
      <VerticalSpacer amount={SPACING}>
        {children && <div>{children}</div>}

        {footer}
      </VerticalSpacer>
    )}
  </Wrapper>
)

export { default as Section } from './components/Section'
export default PageLayout
